<template>
  <div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <div class="panel panel-default bk-bg-white">
        <div class="panel-heading bk-bg-white">
          <h6><i class="fa fa-indent red"></i>产品信息编辑</h6>
          <div class="panel-actions">
            <a href="#" class="btn-minimize"><i class="fa fa-caret-up"></i></a>
            <a href="#" class="btn-close"><i class="fa fa-times"></i></a>
          </div>
        </div>
        <div class="panel-body">
          <form class="form-horizontal" @submit.prevent="onSubmit">
            <div class="form-group">
              <label class="col-md-3 control-label" for="select">工厂列表</label>
              <div class="col-md-9">
                <select id="select" name="select" class="form-control" size="1" v-model="corporateIndex" @change="selectCorporate">
                  <option value="" disabled>请选择一个工厂</option>
                  <option v-for="(option,index) in corporateList" :key="option.id" :value="index">
                    {{ option.corporateName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >仓位</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.position"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >货号</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.artNo"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >品名</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.name"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >当前件数</label
              >
              <div class="col-md-9">
                <input
                  type="number"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="0"
                  v-model="formData.number"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >装箱数/PCS</label
              >
              <div class="col-md-9">
                <input
                  type="number"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="0"
                  v-model="formData.pcs"
                  @change="calculateGrossWeight"
                />
              </div>
            </div>
            <div class="form-group" v-if="!'0'.includes(route.query.operate)">
              <label class="col-md-3 control-label" for="text-input"
                >参考价格(元)</label
              >
              <div class="col-md-9">
                <input
                  type="number"
                  step="0.01"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="0"
                  v-model="formData.defaultPrice"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >克重</label
              >
              <div class="col-md-9">
                <input
                  type="number"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="0"
                  v-model="formData.grammage"
                  @change="calculateGrossWeight"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >毛重(kg)</label
              >
              <div class="col-md-9">
                <input
                  type="number"
                  step="0.01"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="0"
                  v-model="formData.grossWeight"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >箱规(cm)长</label
              >
              <div class="col-md-9">
                <input
                  type="number"
                  step="0.01"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="0"
                  v-model="formData.long"
                  @change="calculateVolume"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >箱规(cm)宽</label
              >
              <div class="col-md-9">
                <input
                  type="number"
                  step="0.01"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="0"
                  v-model="formData.wide"
                  @change="calculateVolume"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >箱规(cm)高</label
              >
              <div class="col-md-9">
                <input
                  type="number"
                  step="0.01"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="0"
                  v-model="formData.high"
                  @change="calculateVolume"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >体积</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.volume"
           
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >备注</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.remark"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="file-input"
                >图片</label
              >
              <div class="col-md-9">
                <input
                  type="file"
                  id="file-input"
                  name="file-input"
                  @change="hasImage"
                />
                <img v-if="imageSrc" :src="imageSrc" alt="loading" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-9 col-sm-offset-3">
                <button class="bk-margin-5 btn btn-info">提交</button>
                <button
                  type="reset"
                  class="bk-margin-5 btn btn-default"
                  @click="reset"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
//import { useStore } from "vuex"; 
import { useRoute,useRouter } from "vue-router";
import http from "../http.js";
import Big from 'big.js';

const corporateList=ref([]);
const corporateIndex=ref();
const id = ref();
const route = useRoute();
const router=useRouter ();
const imageSrc = ref();
const formData = ref({
  id: "",
  position:"",
  img: "",
  artNo: "",
  name: "",
  number:"",
  pcs: "",
  grammage:"",
  volume: "",
  boxGauge: "",
  long:"",
  wide:"",
  high:"",
  grossWeight: "",
  remark: "",
  defaultPrice:"",
  supplierName:"",
  supplierId:""
});

{
  //const store=useStore();
 //const token=store.state.token;
 // alert(token)
  id.value = route.query.id;
  if (typeof id.value !== "undefined") {
    http
      .get("/product/" + id.value)
      .then((response) => {
        
        formData.value.id = id.value;
        formData.value.position = response.data.position;
        formData.value.artNo = response.data.artNo;
        formData.value.name = response.data.name;
        formData.value.number = response.data.number;
        formData.value.pcs = response.data.pcs;
        formData.value.defaultPrice=response.data.defaultPrice;
        formData.value.grammage = response.data.grammage;
        formData.value.volume = response.data.volume;
        formData.value.boxGauge = response.data.boxGauge;
        const boxs=response.data.boxGauge.split('*');
        formData.value.long=boxs[0];
        formData.value.wide=boxs[1];
        formData.value.high=boxs[2];
        formData.value.grossWeight = response.data.grossWeight;
        formData.value.remark = response.data.remark;
        formData.value.img = response.data.img;
        formData.value.supplierId=response.data.supplierId;
        formData.value.supplierName=response.data.supplierName;
        imageSrc.value= response.data.img;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  searchSCInfo();
}

function hasImage(event) {
  http
    .post(
      "/utils/upload/image",
      { file: event.target.files[0] },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      imageSrc.value = response.data;
      formData.value.img=imageSrc.value;
      // 处理响应
      console.log(response);
    })
    .catch((error) => {
      // 处理错误
      console.error(error);
    });
}

function onSubmit() {
  if (typeof id.value !== "undefined") {
    http
      .post("/product/update", formData.value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        router.back();
        // 处理响应
        console.log(response);
      })
      .catch((error) => {
        // 处理错误
        console.error(error);
      });
  }else{
    http
      .post("/product/add", formData.value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        router.back();
        // 处理响应
        console.log(response);
      })
      .catch((error) => {
        // 处理错误
        console.error(error);
      });
  }
}

function reset(){
  imageSrc.value = null;
  formData.value.position="";
  formData.value.img= "";
  formData.value.artNo= "";
  formData.value.name= "";
  formData.value.number="";
  formData.value.pcs= "";
  formData.value.grammage="";
  formData.value.volume= "";
  formData.value.boxGauge= "";
  formData.value.grossWeight= "";
  formData.value.remark= "";
  formData.value.supplierId="";
  formData.value.supplierName="";
  formData.value.long="";
  formData.value.wide="";
  formData.value.high="";
}

function calculateVolume(){
  if(isNumber(formData.value.long)&&isNumber(formData.value.wide)&&isNumber(formData.value.high)){
    formData.value.volume=new Big(formData.value.long).mul(new Big(formData.value.wide)).mul(new Big(formData.value.high)).div(1000000);
    formData.value.boxGauge=formData.value.long+'*'+formData.value.wide+'*'+formData.value.high;
  }
}
function calculateGrossWeight(){
  if(isNumber(formData.value.pcs)&&isNumber(formData.value.grammage)){
     formData.value.grossWeight=new Big(formData.value.pcs).mul(new Big(formData.value.grammage)).div(1000);
  }
}

function isNumber(value) {
    return /^-?\d+(\.\d+)?$/.test(value); // 正则表达式检查字符串是否只包含数字
}

function searchSCInfo(){
  
  http
    .get("/sc/name?type=S&name="+formData.value.supplierName)
    .then((response) => {
      if (response.data != null) {
        corporateList.value = response.data;  
      } else {
        corporateList.value=[];
      }
    })
    .catch((error) => {
      console.error(error);
    });

}
function selectCorporate(){
  formData.value.supplierId=corporateList.value[corporateIndex.value].id;
  formData.value.supplierName=corporateList.value[corporateIndex.value].corporateName;
  
}

</script>

<style>
</style>