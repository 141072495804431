<template>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="panel panel-default bk-bg-white">
          <div class="panel-body"   style="overflow: auto;">
            <div class="form-inline" >
            <button class="bk-margin-5 btn btn-info" @click="excelTemplate">
              excel模版
            </button>
            <div class="form-group">
              <label class="col-md-3 control-label">入库日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="formData.recordDate"
                />
              </div>
            </div>
            <button  class="bk-margin-5 btn btn-info"  
            onclick="document.getElementById('file-input').click();"
            >
              <input
              type="file"
              id="file-input"
              name="file-input"
              style="display: none;"
              @change="excelUpload"
            />
              excel上传
            </button> 
            <button
            type="reset"
            class="bk-margin-5 btn btn-default"
            @click="reset"
          >
            Reset
          </button>
            
          </div>
            
            <table
            class="table table-bordered table-striped"
            id="datatable-default"
          >
            <thead>
              <tr>
                <th><input type="checkbox" id="checkbox"  @change="handleCheckboxChange" checked></th>
                <th>图片</th>
                <th>货号</th>
                <th>品名</th>
                <th>客户货</th>
                <th>市场货</th>
                <th>PCS</th>
                <th>克重</th>
                <th>箱规</th>
                <th>备注</th>
                <th>校对信息</th>
                
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in excelData" :key="item.id">
                <td><input type="checkbox" :value="item" v-model="selected"></td>
                <td><img v-if="item.img" :src="item.img" alt="loading" /></td>
                <td>{{item.artNo}}</td>
                <td>{{item.name}}</td>
                <td><div>
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="item.inboundCustomerNumber"
                  /></div></td>
                <td><div >
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="item.inboundNumber"
                  /></div></td>
                <td><div >
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="item.pcs"
                  /></div></td>
                <td><div >
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="item.grammage"
                  /></div></td>
                <td><div >
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="item.boxGauge"
                  /></div></td>
                <td><div >
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="item.remark"
                  /></div></td>
                <td>{{ item.msg }}</td>
              </tr>
            </tbody>
          </table>
          <button class="bk-margin-5 btn btn-info" @click="inBatchBound">入库提交</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import http from "../http.js";
  import { ref } from "vue";
  import { useRouter } from "vue-router";
  
  const router = useRouter();
  
  

  
  const selected=ref([]);
  const excelData=ref([]);
  
  const formData=ref({
      supplierName:"",
      supplierId:"",
      recordDate:getCurrentDate()
  });


  

  
 

  
  function getCurrentDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

  function inBatchBound(){
    http
      .post("/purchase/in?supplierId="
      +formData.value.supplierId
      +"&supplierName="+formData.value.supplierName
      +"&recordDate="+formData.value.recordDate
      , selected.value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
       // router.back();
       router.push(
      {
        path:'/inBatchRecord'
        // query:{
        //   id:purchaseId.value
        // }
      });
        // 处理响应
        console.log(response);
      })
      .catch((error) => {
        // 处理错误
        console.error(error);
      });
  }
function excelTemplate(){
  http
    .get("/excel/template?type=0", {responseType:'blob'})
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', decodeURIComponent(response.headers['content-disposition'].split('filename=')[1]));
        document.body.appendChild(link);
        link.click();
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

function excelUpload(){
  http
    .post(
      "/excel/inbatchUpload",
      { file: event.target.files[0] },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      excelData.value = response.data;
      selected.value=response.data;
      // 处理响应
      console.log(response);
    })
    .catch((error) => {
      // 处理错误
      console.error(error);
    });
}

function handleCheckboxChange(){
  if(event.target.checked ){
    selected.value=excelData.value;
  }else{
    selected.value=[];
  }
 
}


function reset() {

  document.getElementById('file-input').value = null;
  
}




  </script>
  
  <style>
  </style>