<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="panel panel-default bk-bg-white">
        <div class="panel-body" style="overflow: auto; max-height: 550px">
          <div class="row">
            <div class="col-sm-6">
              <div class="bk-margin-bottom-10">
                <router-link to="/addModifyUserInfo"
                  ><button id="addItems" class="btn btn-info">
                    Add <i class="fa fa-plus"></i></button
                ></router-link>
              </div>
            </div>
          </div>
          <form class="form-inline" @submit.prevent="search">
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >用户名</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="searchData.username"
                />
              </div>
            </div>
            <button class="bk-margin-5 btn btn-info">搜索</button>
            <button
              type="reset"
              class="bk-margin-5 btn btn-default"
              @click="reset"
            >
              Reset
            </button>
          </form>
          <table
            class="table table-bordered table-striped"
            id="datatable-default"
          >
            <thead>
              <tr>
                <th>用户名</th>
                <th>角色</th>
                <th>备注</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="item.id">
                <td>{{ item.username }}</td>
                <td>{{ trans(item.roles) }}</td>
                <td>{{ item.remark }}</td>
                <td class="actions">
                  <router-link
                    :to="{
                      path: '/addModifyUserInfo',
                      query: { id: item.id },
                    }"
                    class="on-default edit-row"
                    ><i class="fa fa-pencil"></i
                  ></router-link>
                  <a
                    href="#"
                    class="on-default remove-row"
                    @click="deleteProduct(items, item.id, index)"
                    ><i class="fa fa-trash-o"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <!--分页计数器-->
          <div class="container">
            <ul class="pagination">
              <li class="page-item" @click="setTab('previous')">
                <a href="#previous" class="page-link"
                  ><i class="fa fa-angle-double-left"></i
                ></a>
              </li>

              <li
                class="page-item"
                v-for="n in totalPages"
                :key="n"
                :class="{ active: n == page }"
                @click="getProductList(n, 10)"
              >
                <a v-if="n > tab - 10 && n <= tab" class="page-link">{{ n }}</a>
              </li>
              <!-- <li class="page-item active"><a href="#1" class="page-link">1</a></li>
              <li class="page-item"><a href="#2" class="page-link">2</a></li>
              <li class="page-item"><a href="#3" class="page-link">3</a></li>
              <li class="page-item"><a href="#4" class="page-link">4</a></li>
              <li class="page-item"><a href="#5" class="page-link">5</a></li> -->
              <li class="page-item" @click="setTab('next')">
                <a href="#next" class="page-link"
                  ><i class="fa fa-angle-double-right"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import http from "../http.js";
import { ref } from "vue";

const totalCount = ref(0);
const totalPages = ref(0);
const count = ref(0);
const page = ref(0);
const items = ref([{}]);
const tab = ref(10);

const searchData = ref({
  username: "",
});

getProductList(1, 10);

function getProductList(currentPage, currentCount) {
  http
    .get(
      "/user/list?page=" +
        currentPage +
        "&count=" +
        currentCount +
        "&username=" +
        searchData.value.username
    )
    .then((response) => {
      totalCount.value = response.data.totalCount;
      totalPages.value = response.data.totalPages;
      count.value = response.data.count;
      page.value = response.data.page;
      items.value = response.data.items;
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

function deleteProduct(items, id, index) {
  if (confirm("你确定删除这个用户吗？删除后无法恢复，请谨慎操作")) {
    http
      .delete("/user/" + id)
      .then((response) => {
        items.splice(index, 1);
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

function setTab(flag) {
  if (flag == "previous" && tab.value > 10) {
    //alert(tab.value-19)
    getProductList(tab.value - 19, 10);
    tab.value -= 10;
  }
  if (flag == "next" && tab.value < totalPages.value) {
    //alert(tab.value+1)
    getProductList(tab.value + 1, 10);
    tab.value += 10;
  }
}

function search() {
  getProductList(1, 10);
}

function reset() {
  searchData.value.artNo = "";
  searchData.value.name = "";
  searchData.value.number = "";
  getProductList(1, 10);
}

function trans(roles) {
  if ("A" == roles) {
    return "超级管理员";
  }
  if ("B" == roles) {
    return "销售员";
  }
  if ("C" == roles) {
    return "仓管员";
  }
  if ("D" == roles) {
    return "财务";
  }
}
</script>

<style>
</style>