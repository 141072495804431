<template>
  <div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <div class="panel panel-default bk-bg-white">
        <div class="panel-heading bk-bg-white">
          <h6>
            <i class="fa fa-indent red"></i
            >{{ route.query.type == "C" ? "客户信息编辑" : "供应商信息编辑" }}
          </h6>
          <div class="panel-actions">
            <a href="#" class="btn-minimize"><i class="fa fa-caret-up"></i></a>
            <a href="#" class="btn-close"><i class="fa fa-times"></i></a>
          </div>
        </div>
        <div class="panel-body">
          <form class="form-horizontal" @submit.prevent="onSubmit">
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >公司名称</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.corporateName"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >客户名称</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.customerName"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >公司地址</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.companyAddress"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >联系方式</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.phone"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >备注</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.remark"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-9 col-sm-offset-3">
                <button class="bk-margin-5 btn btn-info">提交</button>
                <button
                  type="reset"
                  class="bk-margin-5 btn btn-default"
                  @click="reset"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
//import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import http from "../http.js";

const id = ref();
const route = useRoute();
const router = useRouter();
const formData = ref({
  id: "",
  type: route.query.type,
  corporateName: "",
  customerName: "",
  companyAddress: "",
  phone: "",
  remark: "",
});

{
  //const store=useStore();
  //const token=store.state.token;
  // alert(token)
  id.value = route.query.id;
  if (typeof id.value !== "undefined") {
    http
      .get("/sc/" + id.value)
      .then((response) => {
        formData.value.id = id.value;
        formData.value.corporateName = response.data.corporateName;
        formData.value.customerName = response.data.customerName;
        formData.value.companyAddress = response.data.companyAddress;
        formData.value.phone = response.data.phone;
        formData.value.remark = response.data.remark;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

function onSubmit() {
  if (typeof id.value !== "undefined") {
    http
      .post("/sc/update", formData.value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        router.back();
        // 处理响应
        console.log(response);
      })
      .catch((error) => {
        // 处理错误
        console.error(error);
      });
  } else {
    http
      .post("/sc/add", formData.value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        router.back();
        // 处理响应
        console.log(response);
      })
      .catch((error) => {
        // 处理错误
        console.error(error);
      });
  }
}

function reset() {
  formData.value.corporateName="";
formData.value.customerName="";
formData.value.companyAddress="";
formData.value.type="";
formData.value.phone="";
formData.value.remark="";
}
</script>

<style>
</style>