<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="panel panel-default bk-bg-white">
        <div class="panel-body"   style="overflow: auto;max-height:550px;">
          <div class="row">
            <div  class="col-sm-6">
              <div class="bk-margin-bottom-10">
                <router-link to="/addModifyProductInfo"
                  ><button id="addItems" class="btn btn-info">
                    Add <i class="fa fa-plus"></i></button
                ></router-link>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="bk-margin-bottom-10">
                <button class="bk-margin-5 btn btn-info" @click="excelTemplate">
                  excel模版
                </button>
                <button  class="bk-margin-5 btn btn-info"  
                onclick="document.getElementById('file-input').click();"
                >
                  <input
                  type="file"
                  id="file-input"
                  name="file-input"
                  style="display: none;"
                  @change="excelUpload"
                />
                  excel上传
                </button> 
              </div>
            </div> 
          </div>
          <form class="form-inline" @submit.prevent="search">
            <div class="form-group">
              <label class="col-md-3 control-label" for="select">工厂列表</label>
              <div class="col-md-9">
                <select id="select" name="select" class="form-control" size="1" v-model="searchData.supplierId">
                  <option value="" selected>请选择一个工厂</option>
                  <option v-for="(option) in corporateList" :key="option.id" :value="option.id" >
                    {{ option.corporateName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >货号</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="searchData.artNo"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >品名</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="searchData.name"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >件数小于</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="searchData.number"
                />
              </div>
            </div>
            <button class="bk-margin-5 btn btn-info">搜索</button>
            <button
              type="reset"
              class="bk-margin-5 btn btn-default"
              @click="reset"
            >
              Reset
            </button>
          </form>
          <table
            class="table table-bordered table-striped"
            id="datatable-default"
          >
            <thead>
              <tr>
                <th>仓位</th>
                <th>图片</th>
                <th>货号</th>
                <th>品名</th>
                <th>件数</th>
                <th>已采购件数</th>
                <th>订单占用件数</th>
                <th>剩余件数</th>
                <th>装箱数</th>
                <th v-if="!'0'.includes(route.query.operate)">参考单价(元)</th>
                <th>克重</th>
                <th>体积</th>
                <th>箱规(cm)</th>
                <th>毛重(kg)</th>
                <th>备注</th>
                <th >操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="item.id">
                <td>{{ item.position }}</td>
                <td><img v-if="item.img" :src="item.img" alt="loading" /></td>
                <td>{{ item.artNo }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.number }}</td>
                <td>{{ item.inTransitNumber }}</td>
                <td>{{ item.orderOccupancyNumber }}</td>
                <td>{{ (+item.number)-(+item.orderOccupancyNumber)}}</td>
                <td>{{ item.pcs }}</td>
                <td  v-if="!'0'.includes(route.query.operate)">{{item.defaultPrice}}</td>
                <td>{{ item.grammage }}</td>
                <td>{{ item.volume }}</td>
                <td>{{ item.boxGauge }}</td>
                <td>{{ item.grossWeight }}</td>
                <td>{{ item.remark }}</td>
                <td class="actions">
                  <router-link
                  :to="{
                    path: '/inOutBound',
                    query: { id: item.id },
                  }"
                  class="on-default edit-row"
                  ><i class="fa fa-plus"></i
                ></router-link>
                <router-link
                :to="{
                  path: '/inOutBound',
                  query: { id: item.id },
                }"
                class="on-default edit-row"
                ><i class="fa fa-minus"></i
              ></router-link>
                  <router-link v-if="!'0'.includes(route.query.operate)"
                    :to="{
                      path: '/addModifyProductInfo',
                      query: { id: item.id,operate:route.query.operate},
                    }"
                    class="on-default edit-row"
                    ><i class="fa fa-pencil"></i
                  ></router-link>
                  <a v-if="!'0'.includes(route.query.operate)"
                    href="#"
                    class="on-default remove-row"
                    @click="deleteProduct(items, item.id, index)"
                    ><i class="fa fa-trash-o"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <!--分页计数器-->
          <div class="container">
            <ul class="pagination">
              <li class="page-item" @click="setTab('previous')">
                <a href="#previous" class="page-link"
                  ><i class="fa fa-angle-double-left"></i
                ></a>
              </li>

              <li
                class="page-item"
                v-for="n in totalPages"
                :key="n"
                :class="{ active: n == page }"
                @click="getProductList(n, 10)"
              >
                <a v-if="n > tab - 10 && n <= tab" class="page-link">{{ n }}</a>
              </li>
              <!-- <li class="page-item active"><a href="#1" class="page-link">1</a></li>
              <li class="page-item"><a href="#2" class="page-link">2</a></li>
              <li class="page-item"><a href="#3" class="page-link">3</a></li>
              <li class="page-item"><a href="#4" class="page-link">4</a></li>
              <li class="page-item"><a href="#5" class="page-link">5</a></li> -->
              <li class="page-item" @click="setTab('next')">
                <a href="#next" class="page-link"
                  ><i class="fa fa-angle-double-right"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import http from "../http.js";
import { ref } from "vue";
import {useRoute } from "vue-router";


const route=useRoute();
const totalCount = ref(0);
const totalPages = ref(0);
const count = ref(0);
const page = ref(0);
const items = ref([{}]);
const tab = ref(10);
const corporateList=ref([]);

const searchData = ref({
  artNo: "",
  name: "",
  number: "",
  supplierId:""
});

getProductList(1, 10);
searchSCInfo();

function getProductList(currentPage, currentCount) {
  http
    .get(
      "/product/list?page=" +
        currentPage +
        "&count=" +
        currentCount +
        "&artNo=" +
        searchData.value.artNo +
        "&name=" +
        searchData.value.name +
        "&number=" +
        searchData.value.number+
        "&supplierId="+
        searchData.value.supplierId
    )
    .then((response) => {
      totalCount.value = response.data.totalCount;
      totalPages.value = response.data.totalPages;
      count.value = response.data.count;
      page.value = response.data.page;
      items.value = response.data.items;
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

function deleteProduct(items, id, index) {
  if(confirm("你确定删除这个产品吗？删除后无法恢复，请谨慎操作")) {
    http
      .delete("/product/" + id)
      .then((response) => {
        items.splice(index, 1);
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

function setTab(flag) {
  if (flag == "previous" && tab.value > 10) {
    //alert(tab.value-19)
    getProductList(tab.value - 19, 10);
    tab.value -= 10;
  }
  if (flag == "next" && tab.value < totalPages.value) {
    //alert(tab.value+1)
    getProductList(tab.value + 1, 10);
    tab.value += 10;
  }
}

function search() {
  getProductList(1, 10);
}

function reset() {
  searchData.value.artNo = "";
  searchData.value.name = "";
  searchData.value.number = "";
  searchData.value.supplierId="";
  getProductList(1, 10);
}


function searchSCInfo(){
  
  http
    .get("/sc/name?type=S")
    .then((response) => {
      if (response.data != null) {
        corporateList.value = response.data;  
      } else {
        corporateList.value=[];
      }
    })
    .catch((error) => {
      console.error(error);
    });

}
function excelTemplate(){
  http
    .get("/excel/template?type=1", {responseType:'blob'})
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', decodeURIComponent(response.headers['content-disposition'].split('filename=')[1]));
        document.body.appendChild(link);
        link.click();
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}
function excelUpload(){
  http
    .post(
      "/excel/inbatchProductUpload",
      { file: event.target.files[0] },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      alert("上传成功");
      // 处理响应
      console.log(response);
    })
    .catch((error) => {
      // 处理错误
      console.error(error);
    });
}
</script>

<style>
</style>