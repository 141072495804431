<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="panel panel-default bk-bg-white">
        <div class="panel-body" style="overflow: auto; max-height: 550px">
          <div class="row" v-if="!'10'.includes(route.query.operate)">
            <div class="col-sm-6">
              <div class="bk-margin-bottom-10">
                <router-link to="/addModifyOrderInfo"
                  ><button id="addItems" class="btn btn-info">
                    Add <i class="fa fa-plus"></i></button
                ></router-link>
              </div>
            </div>
          </div>
          <form class="form-inline" @submit.prevent="search">
            <!-- <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >公司名称</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="searchData.corporateName"
                  />
                </div>
              </div> -->
            <div class="form-group">
              <label class="col-md-3 control-label" for="select"
                >工厂列表</label
              >
              <div class="col-md-9">
                <select
                  id="select"
                  name="select"
                  class="form-control"
                  size="1"
                  v-model="searchData.scId"
                >
                  <option value="" selected>请选择一个工厂</option>
                  <option
                    v-for="option in corporateList"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.corporateName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >货号</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="searchData.artNo"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >客户名称</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="searchData.customerName"
                />
              </div>
            </div>
            <div class="form-group" v-if="!'1'.includes(route.query.operate)">
              <label class="col-md-3 control-label" for="select">配货</label>
              <div class="col-md-9">
                <select
                  id="select"
                  name="select"
                  class="form-control"
                  size="1"
                  v-model="searchData.orderPreparationStatus"
                >
                  <option value="" selected>全部</option>
                  <option value="0">未配货</option>
                  <option value="1">部分配货</option>
                  <option value="2">配货完成</option>
                </select>
              </div>
            </div>

            <div class="form-group" v-if="'1'.includes(route.query.operate)">
              <label class="col-md-3 control-label" for="select">支付</label>
              <div class="col-md-9">
                <select
                  id="select"
                  name="select"
                  class="form-control"
                  size="1"
                  v-model="searchData.paymentStatus"
                >
                  <option value="" selected>全部</option>
                  <option value="0">未支付</option>
                  <option value="1">部分支付</option>
                  <option value="2">全部支付</option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-3 control-label" for="select">出库</label>
              <div class="col-md-9">
                <select
                  id="select"
                  name="select"
                  class="form-control"
                  size="1"
                  v-model="searchData.orderOutboundStatus"
                >
                  <option value="" selected>全部</option>
                  <option value="0">未出库</option>
                  <option value="1">部分出库</option>
                  <option value="2">全部出库</option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-3 control-label">订单开始日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="searchData.orderStartDate"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label">订单结束日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="searchData.orderEndDate"
                />
              </div>
            </div>

            <div class="form-group" v-if="!'1'.includes(route.query.operate)">
              <label class="col-md-3 control-label">交货开始日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="searchData.deliveryStartDate"
                />
              </div>
            </div>
            <div class="form-group" v-if="!'1'.includes(route.query.operate)">
              <label class="col-md-3 control-label">交货结束日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="searchData.deliveryEndDate"
                />
              </div>
            </div>
            <div class="form-group" v-if="'1'.includes(route.query.operate)">
              <label class="col-md-3 control-label">付款开始日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="searchData.paymentStartDate"
                />
              </div>
            </div>
            <div class="form-group" v-if="'1'.includes(route.query.operate)">
              <label class="col-md-3 control-label">付款结束日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="searchData.paymentEndDate"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >合同编号</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="searchData.contractNumber"
                />
              </div>
            </div>
            <button class="bk-margin-5 btn btn-info">搜索</button>
            <button
              type="reset"
              class="bk-margin-5 btn btn-default"
              @click="reset"
            >
              Reset
            </button>
          </form>
          <table
            class="table table-bordered table-striped"
            id="datatable-default"
          >
            <thead>
              <tr>
                <th>订单日期</th>
                <th>公司名称</th>
                <th>客户名称</th>
                <th>合同编号</th>

                <th v-if="'1'.includes(route.query.operate)">支付状态</th>
                <th v-if="'1'.includes(route.query.operate)">实付款</th>
                <th v-if="'1'.includes(route.query.operate)">未收款</th>

                <th v-if="!'1'.includes(route.query.operate)">交货日期</th>
                <th>公司地址</th>
                <th>联系方式</th>
                <th v-if="!'1'.includes(route.query.operate)">交货地址</th>
                <th v-if="!'1'.includes(route.query.operate)">备货状态</th>
                <th>出库状态</th>
                <th v-if="!'0'.includes(route.query.operate)">定金</th>
                <th>备注</th>
                <th v-if="!'0'.includes(route.query.operate)">付款方式</th>
                <th v-if="!'0'.includes(route.query.operate)">付款日期</th>
                <th v-if="!'0'.includes(route.query.operate)">总金额</th>
                <th v-if="!'01'.includes(route.query.operate)">操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="item.id">
                <td @click="goOrderDetailsInfo(item.id)">
                  {{ item.orderDate ? item.orderDate.substring(0, 10) : "" }}
                </td>
                <td @click="goOrderDetailsInfo(item.id)">
                  {{ item.corporateName }}
                </td>
                <td @click="goOrderDetailsInfo(item.id)">
                  {{ item.customerName }}
                </td>
                <td @click="goOrderDetailsInfo(item.id)">
                  {{ item.contractNumber }}
                </td>

                <td
                  @click="goOrderDetailsInfo(item.id)"
                  v-if="'1'.includes(route.query.operate)"
                >
                  {{ transPayment(item.paymentStatus) }}
                </td>
                <td
                  @click="goOrderDetailsInfo(item.id)"
                  v-if="'1'.includes(route.query.operate)"
                >
                  {{ item.paymentAmount }}
                </td>
                <td
                  @click="goOrderDetailsInfo(item.id)"
                  v-if="'1'.includes(route.query.operate)"
                >
                  {{ minus(item.totalAmount, item.paymentAmount) }}
                </td>

                <td
                  @click="goOrderDetailsInfo(item.id)"
                  v-if="!'1'.includes(route.query.operate)"
                >
                  {{
                    item.deliveryDate ? item.deliveryDate.substring(0, 10) : ""
                  }}
                </td>
                <td @click="goOrderDetailsInfo(item.id)">
                  {{ item.companyAddress }}
                </td>
                <td @click="goOrderDetailsInfo(item.id)">{{ item.phone }}</td>
                <td
                  @click="goOrderDetailsInfo(item.id)"
                  v-if="!'1'.includes(route.query.operate)"
                >
                  {{ item.deliveryAddress }}
                </td>
                <td
                  @click="goOrderDetailsInfo(item.id)"
                  v-if="!'1'.includes(route.query.operate)"
                >
                  {{ optrans(item.orderPreparationStatus) }}
                </td>
                <td @click="goOrderDetailsInfo(item.id)">
                  {{ ootrans(item.orderOutboundStatus) }}
                </td>
                <td
                  @click="goOrderDetailsInfo(item.id)"
                  v-if="!'0'.includes(route.query.operate)"
                >
                  {{ item.deposit }}
                </td>
                <td @click="goOrderDetailsInfo(item.id)">{{ item.remark }}</td>
                <td
                  @click="goOrderDetailsInfo(item.id)"
                  v-if="!'0'.includes(route.query.operate)"
                >
                  {{ item.paymentMethod }}
                </td>
                <td
                  @click="goOrderDetailsInfo(item.id)"
                  v-if="!'0'.includes(route.query.operate)"
                >
                  {{
                    item.paymentDate ? item.paymentDate.substring(0, 10) : ""
                  }}
                </td>
                <td
                  @click="goOrderDetailsInfo(item.id)"
                  v-if="!'0'.includes(route.query.operate)"
                >
                  {{ item.totalAmount }}
                </td>
                <td class="actions" v-if="!'01'.includes(route.query.operate)">
                  <router-link
                    :to="{
                      path: '/addModifyOrderInfo',
                      query: { id: item.id },
                    }"
                    class="on-default edit-row"
                    ><i class="fa fa-pencil"></i
                  ></router-link>

                  <a
                    href="#"
                    class="on-default remove-row"
                    @click="deleteOrder(items, item.id, index)"
                    ><i class="fa fa-trash-o"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <!--分页计数器-->
          <div class="container">
            <ul class="pagination">
              <li class="page-item" @click="setTab('previous')">
                <a href="#previous" class="page-link"
                  ><i class="fa fa-angle-double-left"></i
                ></a>
              </li>

              <li
                class="page-item"
                v-for="n in totalPages"
                :key="n"
                :class="{ active: n == page }"
                @click="getOrderList(n, 10)"
              >
                <a v-if="n > tab - 10 && n <= tab" class="page-link">{{ n }}</a>
              </li>
              <!-- <li class="page-item active"><a href="#1" class="page-link">1</a></li>
                <li class="page-item"><a href="#2" class="page-link">2</a></li>
                <li class="page-item"><a href="#3" class="page-link">3</a></li>
                <li class="page-item"><a href="#4" class="page-link">4</a></li>
                <li class="page-item"><a href="#5" class="page-link">5</a></li> -->
              <li class="page-item" @click="setTab('next')">
                <a href="#next" class="page-link"
                  ><i class="fa fa-angle-double-right"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import http from "../http.js";
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import Big from "big.js";

const router = useRouter();
const route = useRoute();

const totalCount = ref(0);
const totalPages = ref(0);
const count = ref(0);
const page = ref(0);
const items = ref([{}]);
const tab = ref(10);
const scId = ref("");

const searchData = ref({
  corporateName: "",
  customerName: "",
  orderPreparationStatus: "",
  orderOutboundStatus: "",
  orderStartDate: "",
  orderEndDate: "",
  contractNumber: "",
  deliveryStartDate: "",
  deliveryEndDate: "",
  paymentStatus: "",
  paymentStartDate: "",
  paymentEndDate: "",
  scId: "",
  artNo: "",
});

const corporateList = ref([]);

{
  if (typeof route.query.scId !== "undefined") {
    scId.value = route.query.scId;
  }
  getOrderList(1, 10);
  searchSCInfo();
}

function getOrderList(currentPage, currentCount) {
  http
    .get(
      "/order/list?page=" +
        currentPage +
        "&count=" +
        currentCount +
        "&corporateName=" +
        searchData.value.corporateName +
        "&customerName=" +
        searchData.value.customerName +
        "&orderPreparationStatus=" +
        searchData.value.orderPreparationStatus +
        "&orderOutboundStatus=" +
        searchData.value.orderOutboundStatus +
        "&orderStartDate=" +
        searchData.value.orderStartDate +
        "&orderEndDate=" +
        searchData.value.orderEndDate +
        "&contractNumber=" +
        searchData.value.contractNumber +
        "&deliveryStartDate=" +
        searchData.value.deliveryStartDate +
        "&deliveryEndDate=" +
        searchData.value.deliveryEndDate +
        "&paymentStatus=" +
        searchData.value.paymentStatus +
        "&paymentStartDate=" +
        searchData.value.paymentStartDate +
        "&paymentEndDate=" +
        searchData.value.paymentEndDate +
        "&operate=" +
        route.query.operate +
        "&scId=" +
        searchData.value.scId +
        "&artNo=" +
        searchData.value.artNo
    )
    .then((response) => {
      totalCount.value = response.data.totalCount;
      totalPages.value = response.data.totalPages;
      count.value = response.data.count;
      page.value = response.data.page;
      items.value = response.data.items;
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

function deleteOrder(items, id, index) {
  if (confirm("你确定删除这个订单吗？删除后无法恢复，请谨慎操作")) {
    http
      .delete("/order/" + id)
      .then((response) => {
        items.splice(index, 1);
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

function setTab(flag) {
  if (flag == "previous" && tab.value > 10) {
    //alert(tab.value-19)
    getOrderList(tab.value - 19, 10);
    tab.value -= 10;
  }
  if (flag == "next" && tab.value < totalPages.value) {
    //alert(tab.value+1)
    getOrderList(tab.value + 1, 10);
    tab.value += 10;
  }
}

function search() {
  getOrderList(1, 10);
}

function reset() {
  searchData.value.corporateName = "";
  searchData.value.customerName = "";
  searchData.value.orderPreparationStatus = "";
  searchData.value.orderOutboundStatus = "";
  searchData.value.orderStartDate = "";
  searchData.value.orderEndDate = "";
  searchData.value.contractNumber = "";
  searchData.value.deliveryStartDate = "";
  searchData.value.deliveryEndDate = "";
  searchData.value.paymentStatus = "";
  searchData.value.paymentStartDate = "";
  searchData.value.paymentEndDate = "";
  searchData.value.scId = "";
  searchData.value.artNo = "";

  getOrderList(1, 10);
}

function goOrderDetailsInfo(id) {
  router.push({
    path: "/orderDetailsInfo",
    query: {
      id: id,
      operate: route.query.operate,
      scId: scId.value,
    },
  });
}

function optrans(status) {
  switch (status) {
    case 0:
      return "未备货";
    case 1:
      return "部分备货";
    case 2:
      return "备货完成";
    default:
      return "未备货";
  }
}
function ootrans(status) {
  switch (status) {
    case 0:
      return "未出库";
    case 1:
      return "部分出库";
    case 2:
      return "全部出库";
    default:
      return "未出库";
  }
}
function transPayment(status) {
  switch (status) {
    case 0:
      return "未支付";
    case 1:
      return "部分支付";
    case 2:
      return "全部支付";
  }
}
function minus(number1, number2) {
  if (isNumber(number1) && isNumber(number2)) {
    return new Big(number1).minus(new Big(number2));
  } else {
    return 0;
  }
}

function isNumber(value) {
  return /^-?\d+(\.\d+)?$/.test(value); // 正则表达式检查字符串是否只包含数字
}

function searchSCInfo() {
  http
    .get("/sc/name?type=C")
    .then((response) => {
      if (response.data != null) {
        corporateList.value = response.data;
      } else {
        corporateList.value = [];
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
</script>
  
  <style>
</style>