import {createRouter,createWebHistory} from 'vue-router'

import ProductList from './components/ProductList.vue'
import AddModifyProductInfo from './components/AddModifyProductInfo.vue'
import OrderList from './components/OrderList.vue'
import AddModifyOrderInfo from './components/AddModifyOrderInfo.vue'
import InOutBound from './components/InOutBound.vue'
import InBatch from './components/InBatch.vue'
import OrderDetails from './components/OrderDetails.vue'
import OrderDetailsInfo from './components/OrderDetailsInfo.vue'
import PurchaseList from './components/PurchaseList.vue'
import AddModifyPurchaseInfo from './components/AddModifyPurchaseInfo'
import PurchaseDetails from './components/PurchaseDetails.vue'
import PurchaseDetailsInfo from './components/PurchaseDetailsInfo.vue'
import UserList from './components/UserList.vue'
import AddModifyUserInfo from './components/AddModifyUserInfo.vue'
import LogRecordList from './components/LogRecordList.vue'
import ScList from './components/ScList.vue'
import AddModifyScInfo from './components/AddModifyScInfo.vue'
import InBatchRecord from './components/InBatchRecord.vue'


const routes = [
    {
        path:'/productList',
        component:ProductList
    },
    {
        path:'/addModifyProductInfo',
        component:AddModifyProductInfo
       
    },
    {
        path:'/orderList',
        component:OrderList
    },
    {
        path:'/addModifyOrderInfo',
        component:AddModifyOrderInfo
        
    },
    {
        path:'/inOutBound',
        component:InOutBound
       
    },
    {
        path:'/inBatch',
        component:InBatch
        
    },{
        path:'/orderDetails',
        component:OrderDetails
       
    },{
        path:'/orderDetailsInfo',
        component:OrderDetailsInfo
        
    },{
        path:'/purchaseList',
        component:PurchaseList
    },{
        path:'/addModifyPurchaseInfo',
        component:AddModifyPurchaseInfo
     
    },{
        path:'/purchaseDetails',
        component:PurchaseDetails
       
    },{
        path:'/purchaseDetailsInfo',
        component:PurchaseDetailsInfo
        
    },{
        path:'/userList',
        component:UserList
    },{
        path:'/addModifyUserInfo',
        component:AddModifyUserInfo
        
    },{
        path:'/logRecordList',
        component:LogRecordList
    },{
        path:'/scList',
        component:ScList
    },{
        path:'/addModifyScInfo',
        component:AddModifyScInfo
    },{
        path:'/inBatchRecord',
        component:InBatchRecord
    }

]

const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes, // `routes: routes` 的缩写
})

export default router;
