<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="panel panel-default bk-bg-white">
        <div class="panel-body"   style="overflow: auto;max-height:550px;">
          <div class="row">
            
          </div>
          <form class="form-inline" @submit.prevent="search">
            <div class="form-group">
              <label class="col-md-3 control-label">订单开始日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="searchData.startDate"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label">订单结束日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="searchData.endDate"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >用户名</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="searchData.username"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >操作类型</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="searchData.operateType"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >操作日志</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="searchData.operateLog"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >IP地址</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="searchData.ipAddress"
                />
              </div>
            </div>
            <button class="bk-margin-5 btn btn-info">搜索</button>
            <button
              type="reset"
              class="bk-margin-5 btn btn-default"
              @click="reset"
            >
              Reset
            </button>
          </form>
          <table
            class="table table-bordered table-striped"
            id="datatable-default"
          >
            <thead>
              <tr>
                <th>日期</th>
                <th>用户名</th>
                <th>操作类型</th>
                <th>操作日志</th>
                <th>IP地址</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item) in items" :key="item.id">
                <td>{{ item.operateDate?item.operateDate.substring(0,19):"" }}</td>
                
                <td>{{ item.username }}</td>
                <td>{{ item.operateType }}</td>
                <td>{{ item.operateLog }}</td>
                <td>{{ item.ipAddress }}</td>
                <td>{{ item.remark }}</td>
               
              </tr>
            </tbody>
          </table>
          <!--分页计数器-->
          <div class="container">
            <ul class="pagination">
              <li class="page-item" @click="setTab('previous')">
                <a href="#previous" class="page-link"
                  ><i class="fa fa-angle-double-left"></i
                ></a>
              </li>

              <li
                class="page-item"
                v-for="n in totalPages"
                :key="n"
                :class="{ active: n == page }"
                @click="getLogRecordList(n, 10)"
              >
                <a v-if="n > tab - 10 && n <= tab" class="page-link">{{ n }}</a>
              </li>
              <!-- <li class="page-item active"><a href="#1" class="page-link">1</a></li>
              <li class="page-item"><a href="#2" class="page-link">2</a></li>
              <li class="page-item"><a href="#3" class="page-link">3</a></li>
              <li class="page-item"><a href="#4" class="page-link">4</a></li>
              <li class="page-item"><a href="#5" class="page-link">5</a></li> -->
              <li class="page-item" @click="setTab('next')">
                <a href="#next" class="page-link"
                  ><i class="fa fa-angle-double-right"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import http from "../http.js";
import { ref } from "vue";



const totalCount = ref(0);
const totalPages = ref(0);
const count = ref(0);
const page = ref(0);
const items = ref([{}]);
const tab = ref(10);

const searchData = ref({
  startDate: "",
  endDate:"",
  username: "",
  operateType: "",
  operateLog:"",
  ipAddress:"",
});

getLogRecordList(1, 10);

function getLogRecordList(currentPage, currentCount) {
  http
    .get(
      "/log/list?page=" +
        currentPage +
        "&count=" +
        currentCount +
        "&startDate=" +
        searchData.value.startDate +
        "&endDate=" +
        searchData.value.endDate +
        "&username=" +
        searchData.value.username +
        "&operateType=" +
        searchData.value.operateType+
        "&operateLog=" +
        searchData.value.operateLog+
        "&ipAddress=" +
        searchData.value.ipAddress
    )
    .then((response) => {
      totalCount.value = response.data.totalCount;
      totalPages.value = response.data.totalPages;
      count.value = response.data.count;
      page.value = response.data.page;
      items.value = response.data.items;
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}


function setTab(flag) {
  if (flag == "previous" && tab.value > 10) {
    //alert(tab.value-19)
    getLogRecordList(tab.value - 19, 10);
    tab.value -= 10;
  }
  if (flag == "next" && tab.value < totalPages.value) {
    //alert(tab.value+1)
    getLogRecordList(tab.value + 1, 10);
    tab.value += 10;
  }
}

function search() {
  getLogRecordList(1, 10);
}

function reset() {
  
  searchData.value.startDate= "";
  searchData.value.endDate="";
  searchData.value.username= "";
  searchData.value.operateType= "";
  searchData.value.operateLog="";
  searchData.value.ipAddress="";
  
  getLogRecordList(1, 10);
}
</script>

<style>
</style>