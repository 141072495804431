import { createStore } from 'vuex';

export default createStore({
    state: {
      token: null,
    },
    getters: {
    },
    mutations: {
      SET_TOKEN(state, token) {
        state.token = token;
        localStorage.setItem('token', token)
      },
      RESET_STATE(state) {
        state.token = null;
        localStorage.clear();
        sessionStorage.clear();
      },
    },
    actions: {
    },
    modules: {
    }
  })