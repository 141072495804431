<template>
  <div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <div class="panel panel-default bk-bg-white">
        <div class="panel-heading bk-bg-white">
          <h6><i class="fa fa-indent red"></i>订单信息编辑</h6>
          <div class="panel-actions">
            <a href="#" class="btn-minimize"><i class="fa fa-caret-up"></i></a>
            <a href="#" class="btn-close"><i class="fa fa-times"></i></a>
          </div>
        </div>
        <div class="panel-body">
          <form class="form-horizontal" @submit.prevent="onSubmit">
            <div class="form-group">
              <label class="col-md-3 control-label">订单日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="formData.orderDate"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="select">公司列表</label>
              <div class="col-md-9">
                <select id="select" name="select" class="form-control" size="1" v-model="corporateIndex" @change="selectCorporate">
                  <option value="" disabled>请选择一个公司</option>
                  <option v-for="(option,index) in corporateList" :key="option.id" :value="index" >
                    {{ option.corporateName }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >客户名称</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.customerName"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >合同编号</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.contractNumber"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label">交货日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="formData.deliveryDate"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >公司地址</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.companyAddress"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >联系方式</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.phone"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >交货地址</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.deliveryAddress"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >订单状态</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.orderStatus"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >定金</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.deposit"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input">
                备注</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.remark"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input">
                付款方式</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.paymentMethod"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label">付款日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="formData.paymentDate"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-9 col-sm-offset-3">
                <button class="bk-margin-5 btn btn-info">下一步</button>
                <button
                  type="reset"
                  class="bk-margin-5 btn btn-default"
                  @click="reset"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
//import { useStore } from "vuex";
import { useRoute,useRouter } from "vue-router";
import http from "../http.js";

const route = useRoute();
const router=useRouter();
const formData = ref({
  id: "",
  cId:"",
  orderDate: "",
  corporateName: "",
  customerName: "",
  contractNumber: "",
  deliveryDate: "",
  companyAddress: "",
  phone: "",
  deliveryAddress: "",
  orderStatus: "",
  deposit: "",
  paymentMethod: "",
  remark: "",
  paymentDate: "",
  totalAmount: "",
});
const corporateList=ref([]);
const corporateIndex=ref();

{
  //const store=useStore();
  //const token=store.state.token;
  // alert(token)
  formData.value.id = route.query.id;
  //alert(formData.value.id+"  "+ formData.value.barCode)

  if (typeof formData.value.id !== "undefined") {
    getOrderInfo();
  }
  searchSCInfo();
}

function getOrderInfo() {
  http
    .get("/order/" + formData.value.id)
    .then((response) => {
      if (response.data != null) {
        formData.value.orderDate = response.data.orderDate?response.data.orderDate.substr(0, 10):"";
        formData.value.cId= response.data.cId;
        formData.value.corporateName = response.data.corporateName;
        formData.value.customerName = response.data.customerName;
        formData.value.contractNumber = response.data.contractNumber;
        formData.value.deliveryDate = response.data.deliveryDate?response.data.deliveryDate.substr(0, 10):"";
        formData.value.companyAddress = response.data.companyAddress;
        formData.value.phone = response.data.phone;
        formData.value.deliveryAddress = response.data.deliveryAddress;
        formData.value.orderStatus = response.data.orderStatus;
        formData.value.deposit = response.data.deposit;
        formData.value.paymentMethod = response.data.paymentMethod;
        formData.value.remark = response.data.remark;
        formData.value.paymentDate = response.data.paymentDate?response.data.paymentDate.substr(0, 10):"";
        formData.value.totalAmount = response.data.totalAmount;
      } else {
        reset();
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

function onSubmit() {
  if (typeof formData.value.id !== "undefined") {
    // alert(JSON.stringify(formData.value))
    http
      .post("/order/update", formData.value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        formData.value.id=response.data;
        routeGo();
        // 处理响应
        console.log(response);
      })
      .catch((error) => {
        // 处理错误
        console.error(error);
      });
  } else {
    //alert(JSON.stringify(formData.value))
    http
      .post("/order/add", formData.value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        formData.value.id=response.data;
        routeGo();
        // 处理响应
        console.log(response);
      })
      .catch((error) => {
        // 处理错误
        console.error(error);
      });
  }
}

function routeGo(){
  router.push(
    {
      path:'/orderDetails',
      query:{
        id:formData.value.id
      }
    }
  );
}

function reset() {
  formData.value.orderDate = "";
  formData.value.corporateName = "";
  formData.value.customerName = "";
  formData.value.contractNumber = "";
  formData.value.deliveryDate = "";
  formData.value.companyAddress = "";
  formData.value.phone = "";
  formData.value.deliveryAddress = "";
  formData.value.orderStatus = "";
  formData.value.deposit = "";
  formData.value.paymentMethod = "";
  formData.value.remark = "";
  formData.value.paymentDate = "";
  formData.value.totalAmount = "";
}

function searchSCInfo(){
  
  http
    .get("/sc/name?type=C&name="+formData.value.corporateName)
    .then((response) => {
      if (response.data != null) {
        corporateList.value = response.data;  
      } else {
        corporateList.value=[];
      }
    })
    .catch((error) => {
      console.error(error);
    });

}

function selectCorporate(){
  formData.value.cId=corporateList.value[corporateIndex.value].id;
  formData.value.corporateName=corporateList.value[corporateIndex.value].corporateName;
  formData.value.companyAddress=corporateList.value[corporateIndex.value].companyAddress;
  formData.value.customerName = corporateList.value[corporateIndex.value].customerName;
  formData.value.phone = corporateList.value[corporateIndex.value].phone;
}
</script>

<style>
.button {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #16d2c3;
  border: none;
  border-radius: 5px;
  box-shadow: 0 9px #999;
}

.button:hover {
  background-color: #3bbfb4;
}

.button:active {
  background-color: #3bbfb4;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
</style>