import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


//Vendor CSS
import './assets/vendor/bootstrap/css/bootstrap.min.css'
import './assets/vendor/skycons/css/skycons.css'
import './assets/vendor/font-awesome/css/font-awesome.min.css'
import './assets/vendor/css/pace.preloader.css'
//Plugins CSS
import './assets/plugins/jquery-ui/css/jquery-ui-1.10.4.min.css'
import './assets/plugins/scrollbar/css/mCustomScrollbar.css'
import './assets/plugins/bootkit/css/bootkit.css'
import './assets/plugins/magnific-popup/css/magnific-popup.css'
import './assets/plugins/fullcalendar/css/fullcalendar.css'
import './assets/plugins/jqvmap/jqvmap.css'
//Theme CSS
import './assets/css/jquery.mmenu.css'
//Page CSS
import './assets/css/style.css'
import './assets/css/add-ons.min.css'



const app=createApp(App);


//app.use(VueRouter)



app.use(store)
app.use(router)
app.mount('#app')


