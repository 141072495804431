<template>
  <div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <div class="panel panel-default bk-bg-white">
        <div class="panel-heading bk-bg-white">
          <h6><i class="fa fa-indent red"></i>用户信息编辑</h6>
          <div class="panel-actions">
            <a href="#" class="btn-minimize"><i class="fa fa-caret-up"></i></a>
            <a href="#" class="btn-close"><i class="fa fa-times"></i></a>
          </div>
        </div>
        <div class="panel-body">
          <form class="form-horizontal" @submit.prevent="onSubmit">
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >用户名</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.username"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >密码</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="originalPassword"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="select">角色</label>
              <div class="col-md-9">
                <select id="select" name="select" class="form-control" size="1" v-model="formData.roles">
                  <option value="A">超级管理员</option>
                  <option value="B">销售员</option>
                  <option value="C">仓管员</option>
                  <option value="D">财务</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >备注</label
              >
              <div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="formData.remark"
                />
              </div>
            </div>
          
            <div class="row">
              <div class="col-sm-9 col-sm-offset-3">
                <button class="bk-margin-5 btn btn-info">提交</button>
                <button
                  type="reset"
                  class="bk-margin-5 btn btn-default"
                  @click="reset"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
//import { useStore } from "vuex"; 
import { useRoute,useRouter } from "vue-router";
import http from "../http.js";
import md5 from "js-md5";

const id = ref();
const route = useRoute();
const router=useRouter ();
const formData = ref({
  id: "",
  username:"",
  password: "",
  roles:"",
  remark: ""
});
const originalPassword = ref("");

{
  //const store=useStore();
 //const token=store.state.token;
 // alert(token)
  id.value = route.query.id;
  if (typeof id.value !== "undefined") {
    http
      .get("/user/" + id.value)
      .then((response) => {
        formData.value.id = id.value;
        formData.value.username = response.data.username;
        formData.value.password = response.data.password;
        formData.value.roles = response.data.roles;
        formData.value.remark = response.data.remark;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}


function onSubmit() {
  formData.value.password = md5(originalPassword.value + "123456");
  if (typeof id.value !== "undefined") {
    http
      .post("/user/update", formData.value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        router.back();
        // 处理响应
        console.log(response);
      })
      .catch((error) => {
        // 处理错误
        console.error(error);
      });
  }else{
    http
      .post("/user/add", formData.value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        router.back();
        // 处理响应
        console.log(response);
      })
      .catch((error) => {
        // 处理错误
        console.error(error);
      });
  }
}

function reset(){
  formData.value.username="";
  formData.value.password= "";
  formData.value.remark= "";
}




</script>

<style>
</style>