<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="panel panel-default bk-bg-white">
        <div class="panel-body" style="overflow: auto">
          <div class="row">
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >采购日期:{{
                  purchaseInfo.purchaseDate
                    ? purchaseInfo.purchaseDate.substring(0, 10)
                    : ""
                }}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >采购单名:{{ purchaseInfo.purchaseName }}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >客人单号:{{ purchaseInfo.customerNumber }}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >出货日期:{{
                  purchaseInfo.shippingDate
                    ? purchaseInfo.shippingDate.substring(0, 10)
                    : ""
                }}</label
              >

              <label class="col-md-3 control-label" for="text-input"
                >工厂名称:{{ purchaseInfo.corporateName }}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >客户名称:{{ purchaseInfo.customerName }}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >工厂电话:{{ purchaseInfo.phone }}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >工厂地址:{{ purchaseInfo.companyAddress }}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >原料粉型:{{ purchaseInfo.rawMaterialType }}</label
              >
            </div>
          </div>

          <div class="form-group">
            <label class="col-md-3 control-label" for="text-input"
              >总件数:{{ summaryData.totalNumber }}件</label
            >
            <!-- <label class="col-md-3 control-label" for="text-input"
              >总金额:{{ summaryData.totalAmount }}元</label
            > -->
            <label class="col-md-3 control-label" for="text-input"
              >总体积:{{ summaryData.totalVolume }}立方米</label
            >
            <label class="col-md-3 control-label" for="text-input"
              >总重量:{{ summaryData.totalWeight }}千克</label
            >
          </div>

          <table
            class="table table-bordered table-striped"
            id="datatable-default"
          >
            <thead>
              <tr>
                <th>图片</th>
                <th>货号</th>
                <th>品名</th>

                <th>PCS</th>
                <th>客户货件数</th>
                <th>市场货件数</th>
                <!-- <th>已入库件数</th>
                <th>入库件数</th>
                <th>入库状态</th> -->
                <th>客户货号</th>
                <th>条形码</th>
                <th>正唛</th>
                <th>侧唛</th>
                <th>花色图片</th>
                <th>备注</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in selected" :key="item.id">
                <td><img v-if="item.img" :src="item.img" alt="loading" /></td>
                <td>{{ item.artNo }}</td>
                <td>{{ item.name }}</td>

                <td>{{ item.pcs }}</td>
                <td>{{ item.purchaseCustomerNumber }}</td>
                <td>{{ item.purchaseNumber }}</td>
                <!-- <td>{{item.inboundedNumber}}</td>
                <td><div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    :placeholder="item.purchaseNumber-item.inboundedNumber"
                    v-model="item.inboundNumber"
                  /></div></td> -->
                <!-- <td>{{pitrans(item.inboundStatus)}}</td> -->
                <td>{{ item.customerArtNo }}</td>
                <td>{{ item.barCode }}</td>
                <td>{{ item.positiveLabel }}</td>
                <td>{{ item.sideLabel }}</td>
                <td>
                  <img v-if="item.decor" :src="item.decor" alt="loading" />
                </td>
                <td>{{ item.premark }}</td>
              </tr>
            </tbody>
          </table>
          <button class="bk-margin-5 btn btn-info" @click="routeBackList">
            返回列表
          </button>
          <button
            v-if="!'0'.includes(route.query.operate)"
            class="bk-margin-5 btn btn-info"
            @click="routeBack"
          >
            上一步
          </button>
          <button
            v-if="!'0'.includes(route.query.operate)"
            class="bk-margin-5 btn btn-info"
            @click="excelDownload"
          >
            excel下载
          </button>
          <!-- <button v-if="'0'.includes(route.query.operate)" class="bk-margin-5 btn btn-info" @click="preIn">
            提交
          </button> -->
          <!-- <button v-if="'0'.includes(route.query.operate)" class="bk-margin-5 btn btn-info" @click="excelTemplate">
            excel模版
          </button>
          <button v-if="'0'.includes(route.query.operate)" class="bk-margin-5 btn btn-info"  
          onclick="document.getElementById('file-input').click();"
          >
            <input
            type="file"
            id="file-input"
            name="file-input"
            style="display: none;"
            @change="excelUpload"
          />
            excel上传
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import http from "../http.js";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Big from "big.js";

const router = useRouter();
const route = useRoute();

const selected = ref([]);
const records = ref([]);
const purchaseId = ref();
const purchaseInfo = ref({
  purchaseName: "",
  customerNumber: "",
  purchaseDate: "",
  shippingDate: "",
  rawMaterialType: "",
  purchaseNumber: "",
  remark: "",
  corporateName: "",
  companyAddress: "",
  customerName: "",
  phone: "",
});

const summaryData = ref({
  totalAmount: 0,
  totalNumber: 0,
  totalVolume: 0,
  totalWeight: 0,
});

{
  purchaseId.value = route.query.id;
}

getPurchaseDetails();

function getPurchaseDetails() {
  http
    .get("/purchase/details?purchaseId=" + purchaseId.value)
    .then((response) => {
      purchaseInfo.value.purchaseName = response.data.purchaseName;
      purchaseInfo.value.customerNumber = response.data.customerNumber;
      purchaseInfo.value.purchaseDate = response.data.purchaseDate;
      purchaseInfo.value.shippingDate = response.data.shippingDate;
      purchaseInfo.value.rawMaterialType = response.data.rawMaterialType;
      purchaseInfo.value.remark = response.data.remark;

      purchaseInfo.value.corporateName = response.data.corporateName;
      purchaseInfo.value.customerName = response.data.customerName;
      purchaseInfo.value.companyAddress = response.data.companyAddress;
      purchaseInfo.value.phone = response.data.phone;

      records.value = response.data.records;
      selected.value = response.data.dtos;
      summary();
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

function summary() {
  // var totalAmount=new Big(0);
  var totalNumber = new Big(0);
  var totalVolume = new Big(0);
  var totalWeight = new Big(0);
  for (var item of selected.value) {
    if (isNumber(item.purchaseNumber)) {
      var purchaseNumber = new Big(item.purchaseNumber);
      if (isNumber(item.purchaseCustomerNumber)) {
        purchaseNumber = purchaseNumber.plus(
          new Big(item.purchaseCustomerNumber)
        );
      }
      // if(isNumber(item.purchasePrice)&&isNumber(item.pcs)){
      //   var purchasePrice=new Big(item.purchasePrice);
      //   var pcs=new Big(item.pcs);
      //   var grammage=new Big(item.grammage);
      //   totalAmount=totalAmount.plus(purchaseNumber.mul(purchasePrice).mul(pcs).mul(grammage).div(1000000));
      // }
      if (isNumber(item.volume)) {
        var volume = new Big(item.volume);
        totalVolume = totalVolume.plus(purchaseNumber.mul(volume));
      }
      if (isNumber(item.grossWeight)) {
        var grammage = new Big(item.grammage);
        var pcs=new Big(item.pcs);
        totalWeight = totalWeight.plus(purchaseNumber.mul(grammage).mul(pcs).div(new Big(1000)));
      }
      totalNumber = totalNumber.plus(purchaseNumber);
    }
  }

  // summaryData.value.totalAmount=totalAmount;
  summaryData.value.totalNumber = totalNumber;
  summaryData.value.totalVolume = totalVolume;
  summaryData.value.totalWeight = totalWeight;
}

function routeBackList() {
  router.push({
    path: "/purchaseList",
    query: {
      operate: route.query.operate,
    },
  });
}

function routeBack() {
  router.push({
    path: "/purchaseDetails",
    query: {
      id: purchaseId.value,
    },
  });
}

function isNumber(value) {
  return /^-?\d+(\.\d+)?$/.test(value); // 正则表达式检查字符串是否只包含数字
}

function excelDownload() {
  http
    .get(
      "/excel/purchaseDetails?purchaseId=" +
        purchaseId.value +
        "&purchaseDate=" +
        purchaseInfo.value.purchaseDate +
        "&corporateName=" +
        purchaseInfo.value.corporateName,
      { responseType: "blob" }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        decodeURIComponent(
          response.headers["content-disposition"].split("filename=")[1]
        )
      );
      document.body.appendChild(link);
      link.click();
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

// function preIn(){

// http
//     .post("/purchase/in?purchaseId="+purchaseId.value, selected.value, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//     .then((response) => {
//       router.push(
//     {
//       path:'/purchaseList',
//       query:{
//         operate:route.query.operate
//       }
//     });
//       // 处理响应
//       console.log(response);
//     })
//     .catch((error) => {
//       // 处理错误
//       console.error(error);
//     });

// }

// function pitrans(status){
//    switch(status){
//      case 0:
//       return '未入库';
//      case 1:
//       return '部分入库';
//      case 2:
//       return '全部入库';
//     default:
//       return '未出库';
//    }
// }
// function excelTemplate(){
//   http
//     .get("/excel/template?type=0", {responseType:'blob'})
//     .then((response) => {
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', decodeURIComponent(response.headers['content-disposition'].split('filename=')[1]));
//         document.body.appendChild(link);
//         link.click();
//       console.log(response);
//     })
//     .catch((error) => {
//       console.error(error);
//     });
// }

// function excelUpload(){
//   http
//     .post(
//       "/excel/inbatchUpload?purchaseId="+purchaseId.value,
//       { file: event.target.files[0] },
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       }
//     )
//     .then((response) => {
//       selected.value = response.data;
//       // 处理响应
//       console.log(response);
//     })
//     .catch((error) => {
//       // 处理错误
//       console.error(error);
//     });
// }
</script>
  
  <style>
</style>