<template>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="panel panel-default bk-bg-white">
          <div class="panel-body"   style="overflow: auto;">
            <div class="row">
        
            </div>
            <form class="form-inline" @submit.prevent="search">
              <div class="form-group">
                <label class="col-md-3 control-label" for="select">工厂列表</label>
                <div class="col-md-9">
                  <select id="select" name="select" class="form-control" size="1" v-model="searchData.supplierId">
                    <option value="" selected>请选择一个工厂</option>
                    <option v-for="(option) in corporateList" :key="option.id" :value="option.id" >
                      {{ option.corporateName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >货号</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="searchData.artNo"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >品名</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="searchData.name"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >件数小于</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="searchData.number"
                  />
                </div>
              </div>
              <button class="bk-margin-5 btn btn-info">搜索</button>
              <button
                type="reset"
                class="bk-margin-5 btn btn-default"
                @click="reset"
              >
                Reset
              </button>
            </form>
            <table
              class="table table-bordered table-striped"
              id="datatable-default"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>图片</th>
                  <th>货号</th>
                  <th>品名</th>
                  <th>件数</th>
                  <th>订单占用件数</th>
                  <th>已采购件数</th>
                  <th>剩余件数</th>
                  <th>克重</th>
                  <th>PCS</th>
                  <th>备注</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="item.id">
                  <td><input type="checkbox" :value="item" v-model="selected"  @change="summary"></td>
                  <td><img v-if="item.img" :src="item.img" alt="loading" /></td>
                  <td>{{ item.artNo }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.number }}</td>
                  <td>{{ item.orderOccupancyNumber }}</td>
                  <td>{{item.inTransitNumber}}</td>
                  <td>{{ (+item.number)-(+item.orderOccupancyNumber)}}</td>
                  <td>{{item.grammage}}</td>
                  <td>{{item.pcs}}</td>
                  <td>{{ item.remark }}</td>
                  <td class="actions">
                    <router-link
                    :to="{
                      path: '/inOutBound',
                      query: { id: item.id },
                    }"
                    class="on-default edit-row"
                    ><i class="fa fa-plus"></i
                  ></router-link>
                  <router-link
                  :to="{
                    path: '/inOutBound',
                    query: { id: item.id },
                  }"
                  class="on-default edit-row"
                  ><i class="fa fa-minus"></i
                ></router-link>
                    <router-link
                      :to="{
                        path: '/addModifyProductInfo',
                        query: { id: item.id },
                      }"
                      class="on-default edit-row"
                      ><i class="fa fa-pencil"></i
                    ></router-link>
                    <a
                      href="#"
                      class="on-default remove-row"
                      @click="deleteProduct(items, item.id, index)"
                      ><i class="fa fa-trash-o"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <!--分页计数器-->
            <div class="container">
              <ul class="pagination">
                <li class="page-item" @click="setTab('previous')">
                  <a href="#previous" class="page-link"
                    ><i class="fa fa-angle-double-left"></i
                  ></a>
                </li>
  
                <li
                  class="page-item"
                  v-for="n in totalPages"
                  :key="n"
                  :class="{ active: n == page }"
                  @click="getProductList(n, 10)"
                >
                  <a v-if="n > tab - 10 && n <= tab" class="page-link">{{ n }}</a>
                </li>
                <!-- <li class="page-item active"><a href="#1" class="page-link">1</a></li>
                <li class="page-item"><a href="#2" class="page-link">2</a></li>
                <li class="page-item"><a href="#3" class="page-link">3</a></li>
                <li class="page-item"><a href="#4" class="page-link">4</a></li>
                <li class="page-item"><a href="#5" class="page-link">5</a></li> -->
                <li class="page-item" @click="setTab('next')">
                  <a href="#next" class="page-link"
                    ><i class="fa fa-angle-double-right"></i
                  ></a>
                </li>
              </ul>
            </div>
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
              >总件数:{{summaryData.totalNumber}}件</label>
              <label class="col-md-3 control-label" for="text-input"
              >总体积:{{summaryData.totalVolume}}立方米</label>
              <label class="col-md-3 control-label" for="text-input"
              >总重量:{{summaryData.totalWeight}}千克</label>
            </div>

            <table
            class="table table-bordered table-striped"
            id="datatable-default"
          >
            <thead>
              <tr>
                <th></th>
                <th>货号</th>
                <th>客户货件数</th>
                <th>市场货件数</th>
                
                <th>客户货号</th>
                <th>条码</th>
                <th>正唛</th>
                <th>侧唛</th>
                <th>花色图片</th>
                <th>备注</th>
                
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in selected" :key="item.id">
                <td><input type="checkbox" :value="item" v-model="selected" @change="summary"></td>
                <td>{{ item.artNo }}</td>
                <td><div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="0"
                    v-model="item.purchaseCustomerNumber"
                    @change="summary"
                  /></div></td>
                <td><div class="col-md-9">
                    <input
                      type="text"
                      id="text-input"
                      name="text-input"
                      class="form-control"
                      placeholder="0"
                      v-model="item.purchaseNumber"
                      @change="summary"
                    /></div></td>
                
                <td><div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="item.customerArtNo"
                  /></div></td>
                  <td><div class="col-md-9">
                    <input
                      type="text"
                      id="text-input"
                      name="text-input"
                      class="form-control"
                      placeholder="Text"
                      v-model="item.barCode"
                    /></div></td>
                    <td><div class="col-md-9">
                      <input
                        type="text"
                        id="text-input"
                        name="text-input"
                        class="form-control"
                        placeholder="Text"
                        v-model="item.positiveLabel"
                      /></div></td>
                      <td><div class="col-md-9">
                        <input
                          type="text"
                          id="text-input"
                          name="text-input"
                          class="form-control"
                          placeholder="Text"
                          v-model="item.sideLabel"
                        /></div></td>
                        <td>
                          <div class="col-md-9">
                          <a 
                          @click="imageUpload('file-input'+item.id)"
                          
                          >
                            <input
                            type="file"
                            :id="'file-input'+item.id"
                            name="file-input"
                            style="display: none;"
                            @change="hasImage(item)"
                          />
                            上传
                          </a>
                          <img v-if="item.decor" :src="item.decor" alt="loading" />
                        </div>
                        </td>
                <td><div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="item.premark"
                  /></div></td>
              </tr>
            </tbody>
          </table>
          <button class="bk-margin-5 btn btn-info" @click="orderSub">订单提交</button>
          <button class="bk-margin-5 btn btn-info" @click="routeBack">上一步</button>
          <!-- <button class="bk-margin-5 btn btn-info" @click="excelTemplate">
            excel模版
          </button>
          <button  class="bk-margin-5 btn btn-info"  
          onclick="document.getElementById('file-input').click();"
          >
            <input
            type="file"
            id="file-input"
            name="file-input"
            style="display: none;"
            @change="excelUpload"
          />
            excel上传
          </button> -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import http from "../http.js";
  import { ref } from "vue";
  import {useRoute, useRouter } from "vue-router";
  import Big from 'big.js';
  
  const router = useRouter();
  const route = useRoute();
  
  const totalCount = ref(0);
  const totalPages = ref(0);
  const count = ref(0);
  const page = ref(0);
  const items = ref([]);
  const tab = ref(10);
  const selected=ref([]);
  const purchaseId=ref();
  const corporateList=ref([]);
  
  const searchData = ref({
    artNo: "",
    name: "",
    number: "",
     supplierId:""
  });
  const summaryData=ref({
    totalNumber:0,
    totalVolume:0,
    totalWeight:0
  });
  
  getProductList(1, 10);
  searchSCInfo();

  {
    purchaseId.value = route.query.id;
    getPurchaseDetails();
  }

  function getPurchaseDetails(){
    http
    .get("/purchase/details?purchaseId=" + purchaseId.value)
    .then((response) => {
      selected.value = response.data.dtos;
      summary();
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
  }
  
  function getProductList(currentPage, currentCount) {
    http
      .get(
        "/product/list?page=" +
          currentPage +
          "&count=" +
          currentCount +
          "&artNo=" +
          searchData.value.artNo +
          "&name=" +
          searchData.value.name +
          "&number=" +
          searchData.value.number+
          "&supplierId="+
          searchData.value.supplierId
      )
      .then((response) => {
        totalCount.value = response.data.totalCount;
        totalPages.value = response.data.totalPages;
        count.value = response.data.count;
        page.value = response.data.page;
        items.value = response.data.items;
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  function deleteProduct(items, id, index) {
    if(confirm("你确定删除这个产品吗？删除后无法恢复，请谨慎操作")) {
      http
        .delete("/product/" + id)
        .then((response) => {
          items.splice(index, 1);
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  
  function setTab(flag) {
    if (flag == "previous" && tab.value > 10) {
      //alert(tab.value-19)
      getProductList(tab.value - 19, 10);
      tab.value -= 10;
    }
    if (flag == "next" && tab.value < totalPages.value) {
      //alert(tab.value+1)
      getProductList(tab.value + 1, 10);
      tab.value += 10;
    }
  }
  
  function search() {
    getProductList(1, 10);
  }
  
  function reset() {
    searchData.value.artNo = "";
    searchData.value.name = "";
    searchData.value.number = "";
    searchData.value.supplierId="";
    getProductList(1, 10);
  }

  function orderSub(){
    http
      .post("/purchase/sub?purchaseId="+purchaseId.value+"&totalNumber="+summaryData.value.totalNumber, selected.value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        router.push(
      {
        path:'/purchaseDetailsInfo',
        query:{
          id:purchaseId.value
        }
      });
        // 处理响应
        console.log(response);
      })
      .catch((error) => {
        // 处理错误
        console.error(error);
      });
  }


  function summary(){
    var totalNumber=new Big(0);
    var totalVolume=new Big(0);
    var totalWeight=new Big(0);
    for(var item of selected.value){
      if(isNumber(item.purchaseNumber)){
        var purchaseNumber=new Big(item.purchaseNumber);
        if(isNumber(item.purchaseCustomerNumber)){
          purchaseNumber=purchaseNumber.plus(new Big(item.purchaseCustomerNumber));
        }
        if(isNumber(item.volume)){
          var volume=new Big(item.volume);
          totalVolume=totalVolume.plus(purchaseNumber.mul(volume));
        }
        if(isNumber(item.grossWeight)){
          var grossWeight=new Big(item.grossWeight);
          totalWeight=totalWeight.plus(purchaseNumber.mul(grossWeight));
        }
        totalNumber=totalNumber.plus(purchaseNumber);
      }     
    }
   
    summaryData.value.totalNumber=totalNumber;
    summaryData.value.totalVolume=totalVolume;
    summaryData.value.totalWeight=totalWeight;
  }

  function routeBack(){
    router.push(
      {
        path:'/addModifyPurchaseInfo',
        query:{
          id:purchaseId.value
        }
      });
  }


  function isNumber(value) {
    return /^-?\d+(\.\d+)?$/.test(value); // 正则表达式检查字符串是否只包含数字
}

// function excelTemplate(){
//   http
//     .get("/excel/template?type=1", {responseType:'blob'})
//     .then((response) => {
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', decodeURIComponent(response.headers['content-disposition'].split('filename=')[1]));
//         document.body.appendChild(link);
//         link.click();
//       console.log(response);
//     })
//     .catch((error) => {
//       console.error(error);
//     });
// }

// function excelUpload(){
//   http
//     .post(
//       "/excel/addPurchaseDetail?purchaseId="+purchaseId.value,
//       { file: event.target.files[0] },
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       }
//     )
//     .then((response) => {
//       selected.value = response.data;
//       // 处理响应
//       console.log(response);
//     })
//     .catch((error) => {
//       // 处理错误
//       console.error(error);
//     });
// }
function searchSCInfo(){
  
  http
    .get("/sc/name?type=S")
    .then((response) => {
      if (response.data != null) {
        corporateList.value = response.data;  
      } else {
        corporateList.value=[];
      }
    })
    .catch((error) => {
      console.error(error);
    });

}

function hasImage(item) {
  http
    .post(
      "/utils/upload/image",
      { file: event.target.files[0] },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
       item.decor = response.data;
      // 处理响应
      console.log(response);
    })
    .catch((error) => {
      // 处理错误
      console.error(error);
    });
}

function imageUpload(id){
  document.getElementById(id).click();
}


  </script>
  
  <style>
  </style>