<template>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="panel panel-default bk-bg-white">
          <div class="panel-body"   style="overflow: auto;">
            <div class="form-inline" >
                <div class="form-group">
                    <label class="col-md-3 control-label" for="select">工厂列表</label>
                    <div class="col-md-9">
                      <select id="select" name="select" class="form-control" size="1" v-model="searchData.supplierId">
                        <option value="" selected>请选择一个工厂</option>
                        <option v-for="(option) in corporateList" :key="option.id" :value="option.id" >
                          {{ option.corporateName }}
                        </option>
                      </select>
                    </div>
                  </div>
            <div class="form-group">
              <label class="col-md-3 control-label">入库开始日期</label>
              <div class="col-md-9">
                <input
                  type="date"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  v-model="searchData.recordStartDate"
                />
              </div>
            </div>
            <div class="form-group">
                <label class="col-md-3 control-label">入库结束日期</label>
                <div class="col-md-9">
                  <input
                    type="date"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    v-model="searchData.recordEndDate"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >货号</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="searchData.artNo"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >品名</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="searchData.name"
                  />
                </div>
              </div>
            <button class="bk-margin-5 btn btn-info" @click="getInBatchRecord(1,10)">查询</button>
            <button
            type="reset"
            class="bk-margin-5 btn btn-default"
            @click="reset"
          >
            Reset
          </button>
          </div>
            
          <table
          class="table table-bordered table-striped"
          id="datatable-default"
        >
          <thead>
            <tr>
              <th>入库日期</th>
              <th>图片</th>
              <th>货号</th>
              <th>品名</th>
              <th>客户货</th>
              <th>市场货</th>
              <th>PCS</th>
              <th>克重</th>
              <th>箱规</th>
              <th>备注</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
                <td>{{item.recordDate?item.recordDate.substring(0,10):""}}</td>
                <td><img v-if="item.img" :src="item.img" alt="loading" /></td>
                <td>{{ item.artNo }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.inboundCustomerNumber}}</td>
                <td>{{ item.inboundNumber }}</td>
                <td>{{item.pcs}}</td>
                <td>{{item.grammage}}</td>
                <td>{{item.boxGauge}}</td>
                <td>{{ item.remark }}</td>
                
               
            </tr>
          </tbody>
        </table>

        <div class="container">
            <ul class="pagination">
              <li class="page-item" @click="setTab('previous')">
                <a href="#previous" class="page-link"
                  ><i class="fa fa-angle-double-left"></i
                ></a>
              </li>

              <li
                class="page-item"
                v-for="n in totalPages"
                :key="n"
                :class="{ active: n == page }"
                @click="getInBatchRecord(n, 10)"
              >
                <a v-if="n > tab - 10 && n <= tab" class="page-link">{{ n }}</a>
              </li>
              <!-- <li class="page-item active"><a href="#1" class="page-link">1</a></li>
              <li class="page-item"><a href="#2" class="page-link">2</a></li>
              <li class="page-item"><a href="#3" class="page-link">3</a></li>
              <li class="page-item"><a href="#4" class="page-link">4</a></li>
              <li class="page-item"><a href="#5" class="page-link">5</a></li> -->
              <li class="page-item" @click="setTab('next')">
                <a href="#next" class="page-link"
                  ><i class="fa fa-angle-double-right"></i
                ></a>
              </li>
            </ul>
          </div>
          
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import http from "../http.js";
  import { ref } from "vue";
//   import { useRouter } from "vue-router";
  
//   const router = useRouter();
const totalCount = ref(0);
const totalPages = ref(0);
const count = ref(0);
const page = ref(0);
const items = ref([{}]);
const tab = ref(10);
  
  const corporateList=ref([]);
  
  const searchData=ref({
      artNo: "",
      name: "",
      supplierName:"",
      supplierId:"",
      recordStartDate:"",
      recordEndDate:""
  });


  getInBatchRecord(1, 10);
  searchSCInfo();

  function reset() {
  searchData.value.artNo = "";
  searchData.value.name = "";
  searchData.value.recordStartDate="";
  searchData.value.recordEndDate="";
  searchData.value.supplierId="";
  getInBatchRecord(1, 10);
}

function getInBatchRecord(currentPage, currentCount) {
  http
    .get(
      "/purchase/inBatchRecord?page=" +
        currentPage +
        "&count=" +
        currentCount +
        "&supplierId=" +
        searchData.value.supplierId +
        "&recordStartDate=" +
        searchData.value.recordStartDate +
        "&recordEndDate=" +
        searchData.value.recordEndDate+
        "&artNo="+searchData.value.artNo+
        "&name="+searchData.value.name
    )
    .then((response) => {
      totalCount.value = response.data.totalCount;
      totalPages.value = response.data.totalPages;
      count.value = response.data.count;
      page.value = response.data.page;
      items.value = response.data.items;
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

function setTab(flag) {
  if (flag == "previous" && tab.value > 10) {
    //alert(tab.value-19)
    getInBatchRecord(tab.value - 19, 10);
    tab.value -= 10;
  }
  if (flag == "next" && tab.value < totalPages.value) {
    //alert(tab.value+1)
    getInBatchRecord(tab.value + 1, 10);
    tab.value += 10;
  }
}

 

  




function searchSCInfo(){
  
  http
    .get("/sc/name?type=S")
    .then((response) => {
      if (response.data != null) {
        corporateList.value = response.data;  
      } else {
        corporateList.value=[];
      }
    })
    .catch((error) => {
      console.error(error);
    });

}








  </script>
  
  <style>
  </style>