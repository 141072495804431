<template>
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <div class="panel panel-default bk-bg-white">
          <div class="panel-heading bk-bg-white">
            <h6><i class="fa fa-indent red"></i>产品入库出库</h6>
            <div class="panel-actions">
              <a href="#" class="btn-minimize"><i class="fa fa-caret-up"></i></a>
              <a href="#" class="btn-close"><i class="fa fa-times"></i></a>
            </div>
          </div>
          <div class="panel-body">
            <div class="form-horizontal">
            <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >货号</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="formData.artNo"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >品名</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="formData.name"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >当前件数</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="formData.number"
                    readonly
                  />
                </div>
              </div>
            </div>
            <form class="form-horizontal" @submit.prevent="onSubmit">
              
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >备注</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="formData.remark"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="file-input"
                  >图片</label
                >
                <div class="col-md-9">
                  <img v-if="imageSrc" :src="imageSrc" alt="loading" />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >入库</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="0"
                    v-model="formData.inBound"
                    @change="inOutBound"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >出库</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="0"
                    v-model="formData.outBound"
                    @change="inOutBound"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-9 col-sm-offset-3">
                  <button class="bk-margin-5 btn btn-info">Submit</button>
                  <button
                    type="reset"
                    class="bk-margin-5 btn btn-default"
                    @click="reset"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from "vue";
  //import { useStore } from "vuex"; 
  import { useRoute,useRouter } from "vue-router";
  import http from "../http.js";
  
  const id = ref();
  const route = useRoute();
  const router=useRouter ();
  const imageSrc = ref();
  const formData = ref({
    id: "",
    position:"",
    img: "",
    artNo: "",
    name: "",
    number:"",
    pcs: "",
    unitPrice: "",
    grammage:"",
    volume: "",
    boxGauge: "",
    grossWeight: "",
    remark: "",
    inBound:"0",
    outBound:"0"
  });
  var originalBound;
  
  {
    //const store=useStore();
   //const token=store.state.token;
   // alert(token)
    id.value = route.query.id;
    if (typeof id.value !== "undefined") {
      http
        .get("/product/" + id.value)
        .then((response) => {
          formData.value.id = id.value;
          formData.value.position = response.data.position;
          formData.value.artNo = response.data.artNo;
          formData.value.name = response.data.name;
          formData.value.number = response.data.number;
          formData.value.pcs = response.data.pcs;
          formData.value.unitPrice = response.data.unitPrice;
          formData.value.grammage = response.data.grammage;
          formData.value.volume = response.data.volume;
          formData.value.boxGauge = response.data.boxGauge;
          formData.value.grossWeight = response.data.grossWeight;
          formData.value.remark = response.data.remark;
          formData.value.img = response.data.img;
          imageSrc.value= response.data.img;
          originalBound=parseInt(response.data.number);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  
  function onSubmit() {
    if (typeof id.value !== "undefined") {
      http
        .post("/product/update", formData.value, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          router.back();
          // 处理响应
          console.log(response);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    }else{
      http
        .post("/product/add", formData.value, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          router.back();
          // 处理响应
          console.log(response);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    }
  }
  
  function reset(){
    formData.value.number=originalBound;
    formData.value.remark="";
    formData.value.inBound="0";
    formData.value.outBound= "0";
  }
  function inOutBound(){
    if(!Number.isFinite(+formData.value.inBound)){
        formData.value.inBound="0";
    }
    if(!Number.isFinite(+formData.value.outBound)){
        formData.value.outBound="0";
    }
      formData.value.number=originalBound + (+formData.value.inBound) - (+formData.value.outBound);
  }
  </script>

  
  
  <style>
  </style>