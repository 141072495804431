// http.js
import axios from 'axios';

const http = axios.create({
  //baseURL: 'http://localhost:8080', // 基础URL
  baseURL: 'https://qingge.online/psi', // 基础URL
  timeout: 10000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*' 
  }
  
});

// 请求拦截器
http.interceptors.request.use(config => {
  // 可以在这里添加例如token等配置
  // config.headers['Authorization'] = 'Bearer yourToken';
  const token = localStorage.getItem('token')
  if (token != null){
    config.headers['Authorization'] = token;
  }
   
  return config;
}, error => {
  // 请求错误处理
  return Promise.reject(error);
});

// 响应拦截器
http.interceptors.response.use(response => {
  if(response.data.code == 200){
    return response.data;
  }else
  // 对响应数据做处理，例如只返回data部分
  if (response.data.code == 400||response.data.code == 500) {
    alert(response.data.msg);
    this.$store.commit('RESET_STATE')
  }else{
    //alert(response.headers)
    return response;
  }
  
}, error => {
  // 响应错误处理

  return Promise.reject(error);
});

export default http;