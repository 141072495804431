<template>	
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="panel panel-default bk-bg-white">
          <div class="panel-body"  style="overflow: auto;max-height:550px;">
            <div class="row"  v-if="!'0'.includes(route.query.operate)">
              <div class="col-sm-6">
                <div class="bk-margin-bottom-10">
                  <router-link to="/addModifyPurchaseInfo"><button id="addItems" class="btn btn-info"> Add <i class="fa fa-plus"></i> </button></router-link>
                </div>
              </div>
            </div>
            <form class="form-inline" @submit.prevent="search">
              <!-- <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >公司名称</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="searchData.corporateName"
                  />
                </div>
              </div> -->
              <div class="form-group">
                <label class="col-md-3 control-label" for="select">工厂列表</label>
                <div class="col-md-9">
                  <select id="select" name="select" class="form-control" size="1" v-model="searchData.supplierId">
                    <option value="" selected>请选择一个工厂</option>
                    <option v-for="(option) in corporateList" :key="option.id" :value="option.id" >
                      {{ option.corporateName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >货号</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="searchData.artNo"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >客户名称</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="searchData.customerName"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label" for="text-input"
                  >采购单名</label
                >
                <div class="col-md-9">
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    placeholder="Text"
                    v-model="searchData.purchaseName"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label">采购开始日期</label>
                <div class="col-md-9">
                  <input
                    type="date"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    v-model="searchData.purchaseStartDate"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label">采购结束日期</label>
                <div class="col-md-9">
                  <input
                    type="date"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    v-model="searchData.purchaseEndDate"
                  />
                </div>
              </div>
      
              <div class="form-group">
                <label class="col-md-3 control-label">出货开始日期</label>
                <div class="col-md-9">
                  <input
                    type="date"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    v-model="searchData.shippingStartDate"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-3 control-label">出货结束日期</label>
                <div class="col-md-9">
                  <input
                    type="date"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    v-model="searchData.shippingEndDate"
                  />
                </div>
              </div>
              <button class="bk-margin-5 btn btn-info">搜索</button>
              <button
              type="reset"
              class="bk-margin-5 btn btn-default"
              @click="reset"
            >
              Reset
            </button>
            </form>
            <table class="table table-bordered table-striped" id="datatable-default">
              <thead>
                <tr>
                  <th>采购日期</th>
                  <th>工厂名称</th>
                  <th>客户名称</th>
                  <th>采购单名</th>
                  <th>客人单号</th>
                  <th>出货日期</th>
                  <th>公司地址</th>
                  <th>联系方式</th>
                  <th>原料粉型</th>
                  <th>采购总件数</th>
                  
                  <th>备注</th>
                  <th  v-if="!'0'.includes(route.query.operate)">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in items" :key="item.id">
                  <td @click="goOrderDetailsInfo(item.id)">{{item.purchaseDate?item.purchaseDate.substring(0,10):""}}</td>
                  <td @click="goOrderDetailsInfo(item.id)">{{item.corporateName}}</td>
                  <td @click="goOrderDetailsInfo(item.id)">{{item.customerName}}</td>
                  <td @click="goOrderDetailsInfo(item.id)">{{item.purchaseName}}</td>
                  <td @click="goOrderDetailsInfo(item.id)">{{item.customerNumber}}</td>
                  <td @click="goOrderDetailsInfo(item.id)">{{item.shippingDate?item.shippingDate.substring(0,10):""}}</td>
                  <td @click="goOrderDetailsInfo(item.id)">{{item.companyAddress}}</td>
                  <td @click="goOrderDetailsInfo(item.id)">{{item.phone}}</td>
                  <td @click="goOrderDetailsInfo(item.id)">{{item.rawMaterialType}}</td>
                  <td @click="goOrderDetailsInfo(item.id)">{{item.purchaseNumber}}</td>
                  
                  <td @click="goOrderDetailsInfo(item.id)">{{item.remark}}</td>
                  <td  class="actions"  v-if="!'0'.includes(route.query.operate)">
                
                    <router-link  :to="{path:'/addModifyPurchaseInfo',query:{id:item.id}}"   class="on-default edit-row"><i class="fa fa-pencil"></i></router-link>
           
                    <a href="#" class="on-default remove-row" @click="deletePurchase(items,item.id,index)"><i class="fa fa-trash-o"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <!--分页计数器-->
            <div class="container">
              <ul class="pagination">
                <li class="page-item" @click="setTab('previous')"><a href="#previous" class="page-link"><i class="fa fa-angle-double-left"></i></a></li>
  
                <li  class="page-item" v-for="n in totalPages" :key="n" :class="{ active: n==page }" @click="getPurchaseList(n,10)">
                  <a  v-if="n>tab-10&&n<=tab" class="page-link">{{n}}</a>
                </li>
                <!-- <li class="page-item active"><a href="#1" class="page-link">1</a></li>
                <li class="page-item"><a href="#2" class="page-link">2</a></li>
                <li class="page-item"><a href="#3" class="page-link">3</a></li>
                <li class="page-item"><a href="#4" class="page-link">4</a></li>
                <li class="page-item"><a href="#5" class="page-link">5</a></li> -->
                <li class="page-item" @click="setTab('next')"><a href="#next" class="page-link"><i class="fa fa-angle-double-right"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </template>
  
  <script setup>
  
    import http from '../http.js'
    import {ref} from 'vue'
    import { useRouter ,useRoute} from "vue-router";

    const router = useRouter();
    const route=useRoute();
  
  
    const totalCount=ref(0)
    const totalPages=ref(0)
    const count=ref(0)
    const page=ref(0)
    const items=ref([{}])
    const tab=ref(10)
    

    const searchData = ref({
      corporateName:"",
      customerName:"",
      purchaseName:"",
      purchaseStartDate:"",
      purchaseEndDate:"",
      shippingStartDate:"",
      shippingEndDate:"",
      purchaseInboundStatus:"",
      supplierId:"",
      artNo:""
    });

    const corporateList=ref([]);

    
    getPurchaseList(1,10)
    searchSCInfo();
    
  
    function getPurchaseList(currentPage,currentCount){
      http.get('/purchase/list?page='+currentPage+'&count='+currentCount
      +"&corporateName="+searchData.value.corporateName
+"&customerName="+searchData.value.customerName
+"&purchaseName="+searchData.value.purchaseName
+"&purchaseStartDate="+searchData.value.purchaseStartDate
+"&purchaseEndDate="+searchData.value.purchaseEndDate
+"&shippingStartDate="+searchData.value.shippingStartDate
+"&shippingEndDate="+searchData.value.shippingEndDate
+"&purchaseInboundStatus="+searchData.value.purchaseInboundStatus
+"&supplierId="+searchData.value.supplierId
+"&artNo="+searchData.value.artNo
      )
         .then(response=>{
          totalCount.value=response.data.totalCount;
          totalPages.value=response.data.totalPages;
          count.value=response.data.count;
          page.value=response.data.page;
          items.value=response.data.items;
          console.log(response)
         }).catch(error=>{
          console.error(error)
         })
    }
  
    function deletePurchase(items,id,index){
      if(confirm("你确定删除这个采购单吗？删除后无法恢复，请谨慎操作")) {
      http.delete("/purchase/" + id)
      .then(response=>{
        items.splice(index, 1)
        console.log(response)
      }).catch(error=>{
        console.error(error)
      })
    }
    }
  
    function setTab(flag){
       if(flag=='previous'&&tab.value>10){
          //alert(tab.value-19)
          getPurchaseList(tab.value-19,10);
          tab.value-=10;
         
       }
       if(flag=='next'&&tab.value<totalPages.value){
        //alert(tab.value+1)
        getPurchaseList(tab.value+1,10);
        tab.value+=10;
          
       }
    }
  
  function search(){
    getPurchaseList(1,10)
  }

  function reset(){
    searchData.value.corporateName="";
searchData.value.customerName="";
searchData.value.purchaseName="";
searchData.value.purchaseStartDate="";
searchData.value.purchaseEndDate="";
searchData.value.shippingStartDate="";
searchData.value.shippingEndDate="";
searchData.value.purchaseInboundStatus="";
searchData.value.supplierId="";
    getPurchaseList(1,10);
  }

  

  function goOrderDetailsInfo(id){
    router.push(
      {
        path:'/purchaseDetailsInfo',
        query:{
          id:id,
          operate:route.query.operate
        }
      });
  }

  function searchSCInfo(){
  
  http
    .get("/sc/name?type=S")
    .then((response) => {
      if (response.data != null) {
        corporateList.value = response.data;  
      } else {
        corporateList.value=[];
      }
    })
    .catch((error) => {
      console.error(error);
    });

}
  
  </script>
  
  <style>
  
  </style>