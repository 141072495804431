<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="panel panel-default bk-bg-white">
        <div class="panel-body" style="overflow: auto">
          <div class="row">
            <div class="form-group">
              <label class="col-md-3 control-label" for="text-input"
                >订单日期:{{orderInfo.orderDate?orderInfo.orderDate.substring(0,10):""}}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >公司名称:{{orderInfo.corporateName}}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >客户名称:{{orderInfo.customerName}}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >合同编号:{{orderInfo.contractNumber}}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >交货日期:{{orderInfo.deliveryDate?orderInfo.deliveryDate.substring(0,10):""}}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >公司地址:{{orderInfo.companyAddress}}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >联系方式:{{orderInfo.phone}}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >交货地址:{{orderInfo.deliveryAddress}}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >订单状态:{{orderInfo.orderStatus}}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >定金:{{orderInfo.deposit}}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >备注:{{orderInfo.remark}}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >付款方式:{{orderInfo.paymentMethod}}</label
              >
              <label class="col-md-3 control-label" for="text-input"
                >付款日期:{{orderInfo.paymentDate?orderInfo.paymentDate.substring(0,10):""}}</label
              >
            </div>
          </div>

          <div class="form-group">
            <label class="col-md-3 control-label" for="text-input"
              >总件数:{{ summaryData.totalNumber }}件</label
            >
            <label v-if="!'0'.includes(route.query.operate)" class="col-md-3 control-label" for="text-input"
              >总金额:{{ summaryData.totalAmount }}元</label
            >
            <label class="col-md-3 control-label" for="text-input"  v-if="!'1'.includes(route.query.operate)"
              >总体积:{{ summaryData.totalVolume }}立方米</label
            >
            <label class="col-md-3 control-label" for="text-input"  v-if="!'1'.includes(route.query.operate)"
              >总重量:{{ summaryData.totalWeight }}千克</label
            >
            <label class="col-md-3 control-label" for="text-input"  v-if="'1'.includes(route.query.operate)"
            >实付款:{{ orderInfo.paymentAmount }}元</label
          >
          <label class="col-md-3 control-label" for="text-input"  v-if="'1'.includes(route.query.operate)"
            >未收款:{{ summaryData.totalAmount-orderInfo.paymentAmount }}元</label
          >
          </div>

          <table
            class="table table-bordered table-striped"
            id="datatable-default"
          >
            <thead>
              <tr>
                <th>图片</th>
                <th>货号</th>
                <th>品名</th>
                
                <th v-if="!'0'.includes(route.query.operate)">销售单价</th>
                <th>PCS</th>
                <th>箱规(cm)</th>
                <th>客户货</th>
                <th>市场货</th>
                <th>已备货件数</th>
                <th>备货件数</th>
                <th>已发货件数</th>
                <th>发货件数</th>
                <th v-if="!'0'.includes(route.query.operate)">总金额</th>
                <th>备货状态</th>
                <th>发货状态</th>
                <th>客户货号</th>
                <th>条码</th>
                <th>备注</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in selected" :key="item.id">
                <td><img v-if="item.img" :src="item.img" alt="loading" /></td>
                <td>{{ item.artNo }}</td>
                <td>{{ item.name }}</td>
                
                <td v-if="!'0'.includes(route.query.operate)">{{ item.orderPrice }}</td>
                <td><div >
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    :placeholder="item.orderPcs"
                    v-model="item.orderPcs"
                    @change="summary"
                  /></div></td>
                <td>{{item.boxGauge}}</td>
                <td>{{ item.orderCustomerNumber }}</td>
                <td>{{ item.orderNumber }}</td>
                <td>{{ item.preparationedNumber }}</td>
                <td><div >
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    :placeholder="item.orderNumber+item.orderCustomerNumber-item.preparationedNumber"
                    v-model="item.preparationNumber"
                  /></div></td>
                <td>{{ item.outboundedNumber }}</td>
                <td><div >
                  <input
                    type="text"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    :placeholder="item.orderNumber+item.orderCustomerNumber-item.outboundedNumber"
                    v-model="item.outboundNumber"
                  /></div></td>
                <td v-if="!'0'.includes(route.query.operate)">{{item.orderPcs?sum(item):0 }}</td>
                <td >{{optrans(item.preparationStatus)}}</td>
                <td >{{ootrans(item.outboundStatus)}}</td>
                <td>{{item.customerArtNo}}</td>
                <td>{{item.barCode}}</td>
                <td>{{ item.oremark }}</td>
              </tr>
            </tbody>
          </table>
          <button class="bk-margin-5 btn btn-info" @click="routeBackList">
            返回列表
          </button>
          <button v-if="!'01'.includes(route.query.operate)" class="bk-margin-5 btn btn-info" @click="routeBack">
            上一步
          </button>
          <button v-if="'0'.includes(route.query.operate)" class="bk-margin-5 btn btn-info" @click="preOut">
            提交
          </button>
          <button v-if="!'0'.includes(route.query.operate)" class="bk-margin-5 btn btn-info" @click="excelDownload">
            excel下载
          </button>

          <table
          class="table table-bordered table-striped"
          id="datatable-default"
           v-if="'1'.includes(route.query.operate)"
        >
          <thead>
            <tr>
              <th>记录时间</th>
              <th>付款日期</th>
              <th>实付款</th>
              <th>付款金额</th>
              <th>待付款</th>
              <th>备注</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                <div class="col-md-10">
                  <input
                    type="date"
                    id="text-input"
                    name="text-input"
                    class="form-control"
                    v-model="paymentRecord.recordDate"
                  />
                </div>
              </td>
              <td>{{orderInfo.paymentAmount}}元</td>
              <td><div class="col-md-6">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="0"
                  v-model="paymentRecord.onePayment"
                /></div>元</td>
              <td>{{paymentRecord.onePayment?minus(minus(summaryData.totalAmount,orderInfo.paymentAmount),paymentRecord.onePayment):minus(summaryData.totalAmount,orderInfo.paymentAmount)}}元</td>
              <td><div class="col-md-9">
                <input
                  type="text"
                  id="text-input"
                  name="text-input"
                  class="form-control"
                  placeholder="Text"
                  v-model="paymentRecord.remark"
                /></div></td>
              <td><button class="bk-margin-5 btn btn-info" @click="pradd">
                提交
              </button></td>
            </tr>
            <tr v-for="item in paymentRecords" :key="item.id">
              <td>{{item.createdate?item.createdate.substring(0,19):""}}</td>
              <td>{{item.recordDate?item.recordDate.substring(0,10):""}}</td>
              
              <td></td>
              <td>{{ item.onePayment }}元</td>
              <td></td>
              <td>{{item.remark}}</td>
              <td></td>
              
            </tr>
          </tbody>
        </table>

          <table
          class="table table-bordered table-striped"
          id="datatable-default"
        >
          <thead>
            <tr>
              <th>备货-发货日期</th>
              <th>图片</th>
              <th>货号</th>
              <th>品名</th>
              <th>PCS</th>
              <th>箱规(cm)</th>
              <th>备货件数</th>
              <th>发货件数</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in records" :key="item.id">
              <td>{{item.recordDate?item.recordDate.substring(0,19):""}}</td>
              <td><img v-if="item.img" :src="item.img" alt="loading" /></td>
              <td>{{ item.artNo }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.pcs }}</td>
              <td>{{item.boxGauge}}</td>
              <td>{{item.preparationNumber}}</td>
              <td>{{item.outboundNumber}}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import http from "../http.js";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Big from "big.js";


const router = useRouter();
const route = useRoute();

const selected = ref([]);
const records=ref([]);
const orderId = ref();
const orderInfo = ref({
  orderDate: "",
  corporateName: "",
  customerName: "",
  contractNumber: "",
  deliveryDate: "",
  companyAddress: "",
  phone: "",
  deliveryAddress: "",
  orderStatus: "",
  deposit: "",
  paymentMethod: "",
  remark: "",
  paymentDate: "",
  paymentAmount:"",
  paymentStatus:""
});

const summaryData = ref({
  totalAmount: 0,
  totalNumber: 0,
  totalVolume: 0,
  totalWeight: 0,
});

{
  orderId.value = route.query.id;
}

const paymentRecord=ref({
    orderId:route.query.id,
    onePayment:0,
    recordDate:getCurrentDate(),
    remark:""
});

const paymentRecords=ref([]);

getOrderDetails();

getPaymentRecords();


function getCurrentDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function getPaymentRecords(){
  http
    .get("/order/pr?orderId=" + orderId.value)
    .then((response) => {
      paymentRecords.value=response.data;
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

function getOrderDetails() {
  http
    .get("/order/details?orderId=" + orderId.value)
    .then((response) => {
      orderInfo.value.orderDate = response.data.orderDate;
      orderInfo.value.corporateName = response.data.corporateName;
      orderInfo.value.customerName = response.data.customerName;
      orderInfo.value.contractNumber = response.data.contractNumber;
      orderInfo.value.deliveryDate = response.data.deliveryDate;
      orderInfo.value.companyAddress = response.data.companyAddress;
      orderInfo.value.phone = response.data.phone;
      orderInfo.value.deliveryAddress = response.data.deliveryAddress;
      orderInfo.value.orderStatus = response.data.orderStatus;
      orderInfo.value.deposit = response.data.deposit;
      orderInfo.value.paymentMethod = response.data.paymentMethod;
      orderInfo.value.remark = response.data.remark;
      orderInfo.value.paymentDate = response.data.paymentDate;
      orderInfo.value.paymentAmount=response.data.paymentAmount;
      orderInfo.value.paymentStatus=response.data.paymentStatus;
      selected.value = response.data.dtos;
      records.value=response.data.records;
      summary();
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}
function sum(item){
    if(!isNumber(item.orderPrice)){
      item.orderPrice=item.defaultPrice;
    }
    if(!isNumber(item.orderPrice)){
      item.orderPrice=0;
    }
    if(!isNumber(item.orderPcs)){
       item.orderPcs=item.pcs;
    }
    if(!isNumber(item.orderPcs)){
       item.orderPcs=0;
    }
    if(!isNumber(item.orderNumber)){
      item.orderNumber=0;
    }
    if(!isNumber(item.orderCustomerNumber)){
      item.orderCustomerNumber=0;
    }

    
      var orderNumber=new Big(item.orderNumber).plus(new Big(item.orderCustomerNumber));
      var orderPrice=new Big(item.orderPrice);
      var pcs=new Big(item.orderPcs)
      return  orderNumber.mul(orderPrice).mul(pcs);

  }

  function summary(){
   
    var totalAmount=new Big(0);
    var totalNumber=new Big(0);
    var totalVolume=new Big(0);
    var totalWeight=new Big(0);
    for(var item of selected.value){
      if(!isNumber(item.orderPrice)){
      item.orderPrice=item.defaultPrice;
    }
    if(!isNumber(item.orderPrice)){
      item.orderPrice=0;
    }
    if(!isNumber(item.orderPcs)){
       item.orderPcs=item.pcs;
    }
    if(!isNumber(item.orderPcs)){
       item.orderPcs=0;
    }
      if(!isNumber(item.orderNumber)){
      item.orderNumber=0;
    }
    if(!isNumber(item.orderCustomerNumber)){
      item.orderCustomerNumber=0;
    }
      
        var orderNumber=new Big(item.orderNumber).plus(new Big(item.orderCustomerNumber));
        var pcs=new Big(item.orderPcs);
      
        if(isNumber(item.orderPrice)){
          var orderPrice=new Big(item.orderPrice);
          
          totalAmount=totalAmount.plus(orderNumber.mul(orderPrice).mul(pcs));
        }
        if(isNumber(item.volume)){
          var volume=new Big(item.volume);
          totalVolume=totalVolume.plus(orderNumber.mul(volume));
        }
        if(isNumber(item.grammage)){
          var grammage=new Big(item.grammage);
          
          totalWeight=totalWeight.plus(orderNumber.mul(grammage).mul(pcs).div(new Big(1000)));
        }
        totalNumber=totalNumber.plus(orderNumber);
        
    }
   
    summaryData.value.totalAmount=totalAmount;
    summaryData.value.totalNumber=totalNumber;
    summaryData.value.totalVolume=totalVolume;
    summaryData.value.totalWeight=totalWeight;
  }


function routeBackList() {
  router.push({
    path: "/orderList",
    query:{
      operate:route.query.operate,
      scId:route.query.scId
    }
  });
}

function routeBack(){
  router.push(
    {
      path:'/orderDetails',
      query:{
        id:orderId.value
      }
    }
  );
}

function isNumber(value) {
  return /^-?\d+(\.\d+)?$/.test(value); // 正则表达式检查字符串是否只包含数字
}

function minus(number1,number2){
  if(isNumber(number1)&&isNumber(number2)){
    return new Big(number1).minus(new Big(number2));
  }else{
    return 0;
  }
}

function preOut(){

  http
      .post("/order/po?orderId="+orderId.value
      +"&totalAmount="+summaryData.value.totalAmount
      +"&totalNumber="+summaryData.value.totalNumber, selected.value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        router.push(
      {
        path:'/orderList',
        query:{
          operate:route.query.operate
        }
      });
        // 处理响应
        console.log(response);
      })
      .catch((error) => {
        // 处理错误
        console.error(error);
      });

}
function optrans(status){
   switch(status){
     case 0:
      return '未备货';
     case 1:
      return '部分备货';
     case 2:
      return '备货完成';
    default:
      return '未备货';
   }
}
function ootrans(status){
  switch(status){
     case 0:
      return '未出库';
     case 1:
      return '部分出库';
     case 2:
      return '全部出库';
    default:
      return '未出库';
   }
}

function pradd(){
  
  http
      .post("/order/pr/add", paymentRecord.value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        router.push(
      {
        path:'/orderList',
        query:{
          operate:route.query.operate
        }
      });
        // 处理响应
        console.log(response);
      })
      .catch((error) => {
        // 处理错误
        console.error(error);
      });

}

function excelDownload(){
  http
    .get("/excel/orderDetails?orderId="+orderId.value
    +"&orderDate="+orderInfo.value.orderDate
    +"&corporateName="+orderInfo.value.corporateName
    , {responseType:'blob'})
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', decodeURIComponent(response.headers['content-disposition'].split('filename=')[1]));
        document.body.appendChild(link);
        link.click();
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

</script>
  
  <style>
</style>